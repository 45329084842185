import React, {Component} from 'react'
import {InputText} from "primereact/inputtext";
import axios from 'axios'
import {Button} from "primereact/button";
import {SelectButton} from "primereact/selectbutton";
import {InputSwitch} from "primereact/inputswitch";

import TcmsDialogPickImage from "../dialogs/TcmsDialogPickImage";
import {Dropdown} from "primereact/dropdown";
import {Messages} from "primereact/messages";


const STORAGE = process.env.REACT_APP_MTG_STOR
const API = process.env.REACT_APP_MTG_BACK

let iconStyle = {
  width: '35px',
  height: '35px',
  // border: 'solid 0px #aaa',
  borderRadius: '5px',
  objectFit: 'cover',
  cursor: 'pointer'
}

export class Settings extends Component {

  render() {
    return (
      <React.Fragment>
        <Messages ref={(el) => this.messages = el}/>

        <TcmsDialogPickImage webshop_id={this.state.webshop_id} ref={this.TcmsDialogPickImage}/>
        <div className="p-fluid" style={{marginTop: '1rem'}}>
          {/*Save*/}
          <div className="p-field p-grid">
            <div className="p-col-3"/>
            <div className="p-col-5">
              <Button label="Save" onClick={() => this.save()}/>
            </div>
          </div>

          <div className="p-field p-grid">
            <label htmlFor="local_tinycms_theme_id" className="p-col-3">VM Markt</label>
            <div className="p-col-9">
              <Dropdown id="local_tinycms_theme_id"
                        value={this.state.local_virtual_market.vk_name}
                        options={[
                          {label: '---', value: ''},
                          {label: 'VM Lichtfabriek', value: 'vm-lichtfabriek'},
                          {label: 'VM Beeckestijn', value: 'vm-beeckestijn'},
                          {label: 'VM Lentemarkt', value: 'vm-lentemarkt'},
                          {label: 'VM Louwmans', value: 'vm-louwmans'},
                        ]}
                        style={{width: '100%'}}
                        onChange={e => {
                          this.setState({
                            local_virtual_market: {
                              ...this.state.local_virtual_market,
                              vk_name: e.target.value
                            }
                          })
                        }}
              />
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-3">Klant video (vimeo link)</label>
            <div className="p-col-9">
              <InputText value={this.state.local_virtual_market.vk_customervideo}
                         tooltip="Vul een MP4 link in zonder https ervoor dus bijvoorbeeld //player.vimeo.com/external/1234.hd.mp4"
                         tooltipOptions={{position: 'left'}}
                         onChange={(e) => {
                           this.setState({
                             local_virtual_market: {
                               ...this.state.local_virtual_market,
                               vk_customervideo: e.target.value
                             }
                           })
                         }}
              />
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-3">Klant video 2 (vimeo link)</label>
            <div className="p-col-9">
              <InputText value={this.state.local_virtual_market.vk_customervideo_2}
                         tooltip="Vul een MP4 link in zonder https ervoor dus bijvoorbeeld //player.vimeo.com/external/1234.hd.mp4"
                         tooltipOptions={{position: 'left'}}
                         onChange={(e) => {
                           this.setState({
                             local_virtual_market: {
                               ...this.state.local_virtual_market,
                               vk_customervideo_2: e.target.value
                             }
                           })
                         }}
              />
            </div>
          </div>
          <div className="p-field p-grid">
            <label htmlFor="local_tinycms_theme_id" className="p-col-3">Stemmen op muziek thema</label>
            <div className="p-col-9">
              <Dropdown id="local_tinycms_theme_id"
                        value={this.state.local_virtual_market.stemmen_op_muziek}
                        options={[
                          {label: '---', value: ''},
                          {label: 'Kerst', value: 'christmas'},
                          {label: 'Lente', value: 'spring'},
                          {label: 'Winter', value: 'winter'},
                        ]}
                        style={{width: '100%'}}
                        onChange={e => {
                          this.setState({
                            local_virtual_market: {
                              ...this.state.local_virtual_market,
                              stemmen_op_muziek: e.target.value
                            }
                          })
                        }}
              />
            </div>
          </div>
          {[
            'Klant logo 1 (binnenkomst achter)',
            'Klant logo 2 (binnenkomst voor)',
            'Klant logo 2 (binnenkomst links -  bar)',
            'Klant logo 4'
          ].map((label, i) => {
            return <div key={i} className="p-field p-grid">
              <label className="p-col-3">{label}</label>
              <div className="p-col-2">
                <Button label="Pick image" onClick={() => this.TcmsDialogPickImage.current.open((res) => {
                  let local_virtual_market = Object.assign({}, this.state.local_virtual_market)
                  local_virtual_market.vk_components['vk_logo_' + i] = res.substr(STORAGE.length)
                  this.setState({
                    local_virtual_market
                  })
                })}/>
              </div>
              <div className="p-col-2">
                {this.state.local_virtual_market.vk_components['vk_logo_' + i] &&
                <img style={iconStyle} src={STORAGE + this.state.local_virtual_market.vk_components['vk_logo_' + i]}
                     alt=""/>}
              </div>
            </div>
          })}

          <div className="p-grid">
            {/*Schuifjes Widgets*/}
            <div className="p-col-6">
              {this.state.components.map((component) => (
                <div className="p-field p-grid" key={component.value}>
                  <label htmlFor={`vk_component_${component.value}`} className="p-col-6">{component.label}</label>
                  <div className="p-col-6">
                    <InputSwitch id={`vk_component_${component.value}`}
                                 checked={this.state.local_virtual_market.vk_components[component.value]}
                                 onChange={(e) => {
                                   this.setState({
                                     local_virtual_market: {
                                       ...this.state.local_virtual_market,
                                       vk_components: {
                                         ...this.state.local_virtual_market.vk_components,
                                         [component.value]: e.target.value
                                       }
                                     }
                                   })
                                 }}/>
                  </div>
                </div>
              ))}
            </div>
            <div className="p-col-6">
              {this.state.nodes.map((component) => (
                <div className="p-field p-grid" key={component.value}>
                  <label htmlFor={`vk_component_${component.value}`} className="p-col-6">{component.label}</label>
                  <div className="p-col-6">
                    <InputSwitch id={`vk_component_${component.value}`}
                                 checked={this.state.local_virtual_market.vk_nodes[component.value]}
                                 onChange={(e) => {
                                   this.setState({
                                     local_virtual_market: {
                                       ...this.state.local_virtual_market,
                                       vk_nodes: {
                                         ...this.state.local_virtual_market.vk_nodes,
                                         [component.value]: e.target.value
                                       }
                                     }
                                   })
                                 }}/>
                  </div>
                </div>
              ))}
            </div>
          </div>


          {/*Kraampjes*/}
          {this.state.categories.map((category) => (
            <div className="p-field p-grid" key={category.id}>
              <label htmlFor="vk_category_{category.id}" className="p-col-3">{category.name}</label>
              <div className="p-col-5">
                <SelectButton id="vk_category_{category.id}"
                              value={this.state.local_virtual_market.vk_categories[category.id]}
                              options={this.state.categories_options}
                              style={{width: '100%'}}
                              onChange={(e) => {
                                this.setState({
                                  local_virtual_market: {
                                    ...this.state.local_virtual_market,
                                    vk_categories: {
                                      ...this.state.local_virtual_market.vk_categories,
                                      [category.id]: e.target.value
                                    }
                                  }
                                })
                              }}
                />
              </div>
            </div>
          ))}


          {/*Save*/}
          <div className="p-field p-grid">
            <div className="p-col-3"/>
            <div className="p-col-5">
              <Button label="Save" onClick={() => this.save()}/>
            </div>
          </div>

        </div>
      </React.Fragment>

    );
  }

  state = {
    partner_id: this.props.webshop.partner_id,
    webshop_id: this.props.webshop.id,
    local_virtual_market: {
      vk_name: '',
      stemmen_op_muziek: '',
      vk_customervideo: '',
      vk_customervideo_2: '',
      vk_logo_1: '',
      vk_logo_2: '',
      vk_logo_3: '',
      vk_logo_4: '',
      vk_components: {},
      vk_categories: {},
      vk_nodes: {},
    },
    nodes : [
      {value: '1', label:'1. Shoppen op de buitenplaats'},
      {value: '10', label:'2. Entree'},
      {value: '3', label:'3. Centrale hal'},
      {value: '4', label:'4. Theekamer'},
      {value: '5', label:'5. Entertainment kamer'},
      {value: '6', label:'6. Dinerkamer'},
      {value: '8', label:'7. Gang'},
      {value: '7', label:'8. De keuken'},
      {value: '9', label:'9. Kinderkamer'}
    ],
    components: [
      {label: 'Borrelen', value: 'wi_borrelen'},
      {label: 'Stemmen op muziek', value: 'wi_stemmen-op-muziek'},
      {label: 'Kerstrecepten', value: 'wi_kerstrecepten'},
      {label: 'Kinderen', value: 'wi_kinderen'},
      {label: 'Pub Quiz', value: 'wi_pub-quiz'},
      {label: 'Berichten', value: 'wi_berichten'},
      {label: 'Escape room', value: 'wi_escape-room'},
      {label: 'Spotify', value: 'wi_spotify'},
      {label: 'MIBO omgevingen', value: 'wi_mibo'},
      {label: 'Stembus', value: 'wi_poll'},
      {label: 'Top 3 producten', value: 'wi_top-3-producten'}
    ],
    categories: [],
    categories_options: [
      {label: 'Uitgeschakeld', value: 0},
      {label: 'Webshopweergave', value: 1},
      {label: 'Kerstkraam', value: 2},
    ]
  }

  constructor(props) {
    super(props);
    this.TcmsDialogPickImage = React.createRef()
  }

  onBeforeSend(req) {
    let api_token = sessionStorage.getItem('api_token');
    // Set auth!
    req.xhr.setRequestHeader('Authorization', api_token);
    return req;
  }

  save() {
    let save = {
      webshop_id: this.props.match.params.webshop_id,
      partner_id: this.props.match.params.partner_id,
      local_virtual_market: this.state.local_virtual_market,
    }
    axios.post(API + '/tinycms-set-settings', save).then(res => {
      this.messages.show({severity: 'success', detail: 'Opgeslagen', closable: false});
    })

  }

  componentDidMount() {
    this.getAllCategories()
    this.getSettings()
  }

  getSettings() {
    return new Promise(resolve => {
      axios.get(API + '/tinycms-get-settings?webshop_id=' + this.props.match.params.webshop_id).then(res => {
        let local_virtual_market = res.data.local_virtual_market

        if (local_virtual_market === null) local_virtual_market = {}

        if (!local_virtual_market.vk_name) local_virtual_market.vk_name = '';
        if (!local_virtual_market.stemmen_op_muziek) local_virtual_market.stemmen_op_muziek = '';

        // Check if vk_categories exists
        if (!('vk_components' in local_virtual_market)) local_virtual_market.vk_components = {}
        this.state.components.map((component) => {
          if (!local_virtual_market.vk_components[component.value]) {
            local_virtual_market.vk_components[component.value] = false
          }
          return null;
        })

        // Check if vk_categories exists
        if (!local_virtual_market.vk_categories) local_virtual_market.vk_categories = {}
        this.state.categories.map((category) => {
          if (!local_virtual_market.vk_categories[category.id]) {
            local_virtual_market.vk_categories[category.id] = 0
          }
          return null;
        })

        // Check if vk_nodes exists
        if (!local_virtual_market.vk_nodes) local_virtual_market.vk_nodes = {}
        this.state.nodes.map((component) => {
          if (!local_virtual_market.vk_nodes[component.id]) {
            local_virtual_market.vk_nodes[component.id] = 0
          }
          return null;
        })

        this.setState({local_virtual_market: local_virtual_market})
      });
    });
  }

  getAllCategories() {
    return new Promise(resolve => {
      axios.get(API + '/get-all-categories'
      ).then(res => {
        let categories = res.data.filter((category) => {
          return category.show_in_virtual_market === 1;
        })
        this.setState({categories: categories})
      });
    });
  }

  itemChanged(e) {
    let newState = {}

    switch (e.target.type) {
      case 'checkbox':
        newState[e.target.id] = e.target.checked
        break;
      default:
        newState[e.target.id] = e.target.value
        break;
    }
    this.setState(newState);
  }
}

export default Settings

