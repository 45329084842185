export function priceFilter(value, filter) {
    filter = filter.trim()

    if(filter.indexOf(" - ") !== -1) {
        let res = filter.split(" - ");
        let from = res[0];
        let to = res[1];
        from = parseFloat(from.trim());
        to = parseFloat(to.trim());
        if(value < from) return false;
        if(value > to) return false;
        return true;
    }
    else if(filter.indexOf(" tot ") !== -1) {
        let res = filter.split(" tot ");
        let from = res[0];
        let to = res[1];
        from = parseFloat(from.trim());
        to = parseFloat(to.trim());
        if(value < from) return false;
        if(value > to) return false;
        return true;
    }
    else if(filter.charAt(0) === ">") {
        let number = parseFloat(filter.replace(/>/g, "").trim())
        return value > number;
    }
    else if(filter.charAt(0) === "<") {
        let number = parseFloat(filter.replace(/</g, "").trim())
        return value < number;
    }
    else
    {
        let number = parseFloat(filter.replace(/>/g, "").trim())
        return value > number;
    }
}