import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";

let imgStyle = {
  display: 'inline-block',
  width: '400px',
  height: '400px',
  margin: '5px',
  border: '1px solid #a6a6a6',

  borderRadius: '25px',
  objectFit: 'scale-down',


}

class DialogShowDeleteImage extends Component {

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: '440px'}}
      position={this.state.position}
      modal={true}
      onHide={() => {
        if (this.state.visible) {
          this.close()
        }
      }}
    >
      <div className="p-grid">
        <div className="p-col">

          {/* IMG */}
          {this.state.type === 'IMG' &&
          <img style={imgStyle}
               alt=""
               src={this.state.imgSrc}/>}

          {/* VIDEO */}
          {this.state.type === 'VIDEO' &&
          <video style={imgStyle} controls>
            <source src={this.state.imgSrc} type="video/mp4" />
          </video>}

        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-3">
          <Button style={{width: '6em'}} label="OK" onClick={() => {
            this.close()
          }}/>
        </div>
        <div className="p-col-3">
          <Button style={{width: '6em'}} className="p-button-secondary" label="Cancel" onClick={() => {
            this.cancel()
          }}/>
        </div>

        <label style={{cursor: 'pointer'}} htmlFor="dsd_local_background" className="p-col-4">Remove image</label>
        <div className="p-col-1">
          <Checkbox inputId="dsd_local_background"
                    onChange={e => this.setState({remove: !this.state.remove})}
                    checked={this.state.remove}/>
        </div>
      </div>


    </Dialog>

  }

  state = {
    visible: false,
    imgSrc: '',
    header: '',
    position: 'top',
    remove: false
  }

  callback = null

  cancel() {

    this.setState({visible: false})
    if (typeof this.callback === 'function') {
      this.callback(false)
    }

  }

  close() {

    this.setState({visible: false})

    if (typeof this.callback === 'function') {
      // Checkbox remove true or false
      this.callback(this.state.remove)
    }

  }

  open(imgSrc, type, callback) {

    this.callback = callback
    this.setState({
      visible: true,
      remove: false,
      type,
      imgSrc
    })
  }
}

export default DialogShowDeleteImage