import React from 'react';
import Menu from '../components/Menu'
import Auth from '../auth/Auth'
import WebshopProperties from '../components/properties/WebshopProperties'

import WebshopFieldset from '../components/WebshopFieldset'
import axios from "axios";


class PageWebshopProperties extends Auth {
    state = {
        webshop: null
    }

    constructor(props) {
        super(props);
        this.loadWebshopProps();
    }

    loadWebshopProps() {
        axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshop',
            {params: {webshop_id: this.props.match.params.webshop_id}}
        ).then((response) => {
            this.setState({webshop: response.data})
        });
    }

    render() {
        if (!this.state.webshop) return null;
        return (
            <div>
                <Menu {...this.props}/>
                <WebshopFieldset type="webshop_properties" webshop={this.state.webshop}>
                    <WebshopProperties {...this.props} webshop={this.state.webshop}/>
                </WebshopFieldset>
            </div>
        );
    }

}

export default PageWebshopProperties
