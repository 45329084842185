const options = [
  {label: '---', value: 0},
  {label: 'Yes!', value: 1},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

