const options = [
  {label: '---', value: 0},
  {label: '1', value: 90},
  {label: '2', value: 80},
  {label: '3', value: 70},
  {label: '4', value: 60},
  {label: '5', value: 50},
  {label: '6', value: 40},
  {label: '7', value: 30},
  {label: '8', value: 20},
  {label: '9', value: 10},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

