import React, { Component } from 'react';
import { FileService } from '../../services/file-service';
import { Button } from 'primereact/button';
import { saveAs } from 'file-saver';
import { ProgressSpinner } from 'primereact/progressspinner';

class DownloadStocks extends Component {

  render() {
    if (this.state.downloading) return <ProgressSpinner/>;
    return (
      <Button
        className="p-button-success"
        icon="pi pi-cloud-download"
        onClick={()=>this.downloadFile()}
        style={{
          position: 'relative',
          fontSize: '0.75rem',
          marginLeft: '0.5rem',
          width: '2rem'
        }}/>

    )
  };


  constructor(props) {
    super(props);
    this.fileService = new FileService();
    this.state={downloading:false};
  }


  downloadFile = () => {

    this.setState({ downloading: true });

    let self = this;

    this.fileService.getStocksFromServer().then((response) => {

      this.setState({ downloading: false});

      //extract file name from Content-Disposition header not possible
      saveAs(response.data, 'stocks.xlsx');


    }).catch(function (error) {
      self.setState({ downloading: false });
      if (error.response) {
        console.log('Error', error.response.status);
      } else {
        console.log('Error', error.message);
      }
    });
  };


}
export default DownloadStocks
