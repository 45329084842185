import React, {Component} from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Messages} from 'primereact/messages';

import axios from 'axios';
import DialogAreYouSure from "../dialogs/DialogAreYouSure";
import DialogMeeting from "../dialogs/DialogMeeting";

const BACKEND = process.env.REACT_APP_MTG_BACK


export class Meetings extends Component {

    render() {
        return (
            <div className="p-fluid" style={{marginTop: '1rem'}}>
                <Messages ref={(el) => this.messages = el}></Messages>

                <DialogMeeting
                    ref={this.DialogMeeting}
                    onClose={this.onDialogMeetingClose}
                    webshop={this.state.webshop}
                    {...this.props}
                />

                {/* ========================== Dialogs ============================ */}
                <DialogAreYouSure ref={this.DialogAreYouSure}/>

                <div className="p-grid">
                    {/* ========================== Add ============================ */}
                    <div className="p-col-3">
                        <Button label="Add" icon="pi pi-plus"
                                onClick={() => this.DialogMeeting.current.open()}
                                style={{margin: '0 2px'}}
                        />
                    </div>

                    {/* ========================== Sync hosts ============================ */}
                    <div className="p-col-3">
                        <Button label="Sync hosts" icon="pi pi-refresh"
                                onClick={() => this.syncHosts()}
                                style={{margin: '0 2px'}}
                        />
                    </div>
                    {/* ========================== Sync meetings status ============================ */}
                    <div className="p-col-3">
                        <Button label="Sync Meetings" icon="pi pi-refresh"
                                onClick={() => this.syncMeetings()}
                                style={{margin: '0 2px'}}
                        />
                    </div>

                    {/* ========================== Data ============================ */}
                    <div className="p-col-12">
                        <DataTable paginator={true}
                                   rows={20}
                                   value={this.state.rows}
                                   editable={true}
                                   style={{marginTop: '5px'}}
                                   resizableColumns
                                   columnResizeMode="fit"
                        >
                            {/*=======================================================*/}
                            <Column style={{ width:'2.5rem' }}
                                body={(row) => {
                                    return (
                                        <Button icon="pi pi-fw pi-pencil" style={{fontSize:'0.6rem'}} onClick={() => {
                                            this.DialogMeeting.current.open(row.id)
                                            }}
                                        />
                                )}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="topic"
                                header={"Topic"}
                                sortable={true}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="zoom_id"
                                header={"Zoom ID"}
                                sortable={true}
                                style={{width: '10%'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="host"
                                header={"Host"}
                                sortable={true}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="start_url"
                                header={"Start"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`${row.start_url}`} target={`_blank`}>
                                            Start
                                        </a>)
                                }}
                                style={{width: '7%', minWidth: '150px'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="join_url"
                                header={"Join"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`${row.join_url}`} target={`_blank`}>
                                            Join
                                        </a>)
                                }}
                                style={{width: '6%', minWidth: '150px'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="password"
                                header={"Wachtwoord"}
                                sortable={true}
                                style={{width: '10%'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="status"
                                header={"Status"}
                                sortable={true}
                                style={{width: '7%', minWidth: '150px'}}
                            />
                            {/*=======================================================*/}
                            <Column style={{width: '2.5rem'}}
                                    body={(row) => {
                                        return (
                                            <Button icon="pi pi-fw pi-trash" style={{fontSize: '0.6rem'}} onClick={this.deleteMeeting.bind(this, row)}/>)
                                    }}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }

    state = {
        rows: [],
        dialogAddVisible: false
    }

    constructor(props) {
        super(props)
        this.DialogMeeting = React.createRef()
        this.DialogAreYouSure = React.createRef()
        this.messages = React.createRef()
        this.onDialogMeetingClose = this.onDialogMeetingClose.bind(this);
    }

    componentDidMount() {
        this.getMeetings()
    }

    getMeetings() {
        axios.get(BACKEND + '/get-meetings', {params: {webshop_id: this.props.match.params.webshop_id}}).then((response) => {
            if (response.data.msg === 'ok') {
                this.setState({'rows': response.data.rows})
            } else {
                alert(response.data)
            }

        }).catch((error) => {
        });

    }

    deleteMeeting(row, e) {
        this.DialogAreYouSure.current.open(
            "Je staat op het punt deze meeting te verwijderen.\n" +
            "Als je zeker bent klik dan op OK?\n\n", () => {

                axios.post(BACKEND + '/delete-meeting', {id: row.id}).then(response => {
                    if (response.data.msg === 'ok') {
                        this.messages.show({
                            severity: 'success',
                            summary: 'Gelukt',
                            detail: (
                                <React.Fragment>Meeting {row.id} is succesvol verwijderd.<br /></React.Fragment>),
                            sticky: true
                        });
                        this.getMeetings()
                    }

                }).catch(error => {
                    console.log(error);
                    alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
                });
            })

    }

    syncHosts() {
        this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});

        axios.post(BACKEND + '/sync-hosts', {webshop_id: this.props.match.params.webshop_id}).then(response => {
            this.messages.clear();
            if (response.data.msg === 'ok') {
                this.messages.show({
                    severity: 'success',
                    summary: 'Gelukt',
                    detail: (
                        <React.Fragment>{response.data.details.ok} host(s) is/zijn succesvol gesynchroniseerd</React.Fragment>),
                    sticky: true
                });
                if(response.data.details.failed > 0) {
                    this.messages.show({
                        severity: 'warn',
                        summary: 'Gefaald',
                        detail: (
                            <React.Fragment>{response.data.details.failed} host(s) is/zijn niet gesynchroniseerd (bestaan mogelijk niet meer).<br/>
                                <ul>{response.data.errors.map((reptile) => <li>{reptile}</li>)}</ul>
                            </React.Fragment>),
                        sticky: true
                    });
                }
                this.getMeetings()
            } else {
                this.messages.show({
                    severity: 'error',
                    summary: 'Fouten',
                    detail: (
                        <React.Fragment>Er is iets fout gegaan!<br/>{response.data}<br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                    sticky: true
                });
            }

        }).catch(error => {
            this.messages.clear();
            this.messages.show({
                severity: 'error',
                summary: 'Fouten',
                detail: (<React.Fragment>Er is iets fout gegaan!<br/>
                    <pre>{{__html: error}}</pre>
                    <br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                sticky: true
            });
        });
    }

    syncMeetings() {
        this.messages.show({severity: 'info', summary: 'Het verzoek wordt verwerkt....', sticky: true});

        axios.post(BACKEND + '/sync-meetings', {webshop_id: this.props.match.params.webshop_id}).then(response => {
            this.messages.clear();
            if (response.data.msg === 'ok') {
                this.messages.show({
                    severity: 'success',
                    summary: 'Gelukt',
                    detail: (
                        <React.Fragment>{response.data.details.ok} meeting(s) is/zijn succesvol gesynchroniseerd</React.Fragment>),
                    sticky: true
                });
                if (response.data.details.failed > 0) {
                    this.messages.show({
                        severity: 'warn',
                        summary: 'Gefaald',
                        detail: (
                            <React.Fragment>{response.data.details.failed} meeting(s) is/zijn niet gesynchroniseerd (bestaan mogelijk niet meer).<br/>
                                <ul>{response.data.errors.map((reptile) => <li>{reptile}</li>)}</ul>
                            </React.Fragment>),
                        sticky: true
                    });
                }
                this.getMeetings()
            } else {
                this.messages.show({
                    severity: 'error',
                    summary: 'Fouten',
                    detail: (
                        <React.Fragment>Er is iets fout gegaan!<br/>{response.data}<br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                    sticky: true
                });
            }

        }).catch(error => {
            this.messages.clear();
            this.messages.show({
                severity: 'error',
                summary: 'Fouten',
                detail: (<React.Fragment>Er is iets fout gegaan!<br/>
                    <pre>{{__html: error}}</pre>
                    <br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                sticky: true
            });
        });
    }

    onDialogMeetingClose(severity,text) {
        if(severity === 'success') {
            this.messages.show({
                severity: 'success',
                summary: 'Gelukt',
                detail: (
                    <React.Fragment>{text}</React.Fragment>),
                sticky: true
            });
        } else {
            this.messages.show({
                severity: 'error',
                summary: 'Fouten',
                detail: (
                    <React.Fragment>Er is iets fout gegaan!<br/>{text}<br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                sticky: true
            });
        }
        this.getMeetings()
    }

}


export default Meetings
