import React, {Component} from 'react'

import axios from 'axios'

import {Button} from "primereact/button";
import {Messages} from "primereact/messages";
import {MultiSelect} from "primereact/multiselect";

const BE = process.env.REACT_APP_MTG_BACK

class ProductRibbons extends Component {

  render() {

    return (
      <div style={{height:'400px'}}>
        <div className="p-grid">
          <div className="p-col-12"></div>
        </div>
        {/*PRODUCT RIBBON*/}
        <div className="p-grid">
          <div className="p-col-2">
            Ribbons
          </div>
          <div className="p-col-10">

            <MultiSelect style={{width:'100%'}}
                         optionLabel="name"
                         optionValue="id"
                         value={this.state.product_ribbons}
                         options={this.state.ribbons}
                         display="chip"
                         onChange={(e) => {
                           this.setState({product_ribbons: e.value})
                         }}/>

          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-2"></div>
          <div className="p-col-6">
            <Button label="Submit" onClick={() => this.submit()}/>
          </div>
        </div>


        <div className="p-grid">
          <div className="p-col-12">
            <Messages ref={(el) => this.messages = el}></Messages>
          </div>
        </div>

      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  state = {
    ribbons: [],
    product_ribbons: []
  }
  componentDidMount() {

    axios.post(BE + '/get-product-ribbons', {...this.props}).then(res => {
      this.setState({
        ribbons: res.data.ribbons,
        product_ribbons: res.data.product_ribbons
      })
    })
  }

  submit() {
    axios.post(BE + '/set-product-ribbons', {
        product_id: this.props.product_id,
        product_ribbons: this.state.product_ribbons
      }
    ).then(res => {
      this.messages.show({life: 1000, severity: 'success', summary: 'Opgeslagen!', closable: false});
    });

  }

}

export default ProductRibbons
