const options = [
  {label: 'Naar Huisadressen', value: 'address'},
  {label: 'Huisadres zonder afhaalpunt (parcel)', value: 'parcel'},
  {label: 'Naar Bedrijfslokacatie', value: 'central_on_name'},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

