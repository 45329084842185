const options = [
  {label: 'Nee', value: 0},
  {label: 'Ja', value: 1},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

