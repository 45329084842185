import React, {Component} from 'react'
import {FileUpload} from 'primereact/fileupload';
import {InputText} from "primereact/inputtext";
import {Checkbox} from 'primereact/checkbox';
import DialogShowDeleteImage from '../dialogs/DialogShowDeleteImage'

import axios from 'axios'
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {SketchPicker} from 'react-color'
import {OverlayPanel} from 'primereact/overlaypanel';
import {Fieldset} from "primereact/fieldset";

let API = process.env.REACT_APP_MTG_BACK
let STORAGE = process.env.REACT_APP_MTG_STOR

let imgStyle = {
  width: '35px',
  height: '35px',
  margin: '0px 6px 0px 6px',
  border: 'solid 2px',
  borderRadius: '5px',
  objectFit: 'cover',
  cursor: 'pointer'
}

export class Algemeen extends Component {

  /**
   * COLORPICKER
   */
  pickerCalcState(e) {
    const key = this.state.colorField
    return {key, value: `rgb(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`}
  }

  pickerSetColor(e, field) {
    this.setState({colorField: field}, () => {
      // Then open the picker
      this.colorPicker.current.toggle(e)
    })

  }

  pickerGetRgbFromString() {

    let rgbString = this.state.colors[this.state.colorField]

    if (!rgbString || (rgbString && rgbString.indexOf('rgb(') !== 0)) {
      return
    }
    // Must be rgb lik this rgb(1,2,3,0.4)
    let arr = rgbString.split(/[()]/)[1].split(',')
    return {r: arr[0], g: arr[1], b: arr[2], a: arr[3]}
  }

  /**
   *
   * @param fieldName
   * @param label
   * @returns {JSX.Element}
   */
  myColorPicker(fieldName, label, defaultColor) {
    return <div className="p-field p-grid">
      <label htmlFor={fieldName} className="p-col-3">{label}</label>
      <div className="p-col-6 p-inputgroup">

        <Button style={{
          backgroundColor: this.state.colors[fieldName] || defaultColor,
          borderWidth: '1px 0 1px 1px',
          borderColor: this.state.colors[fieldName] || '#ddd',
        }}/>

        <InputText id={fieldName}
                   onClick={(e) => {
                     this.pickerSetColor(e, fieldName)
                   }}
                   readOnly={true}
                   value={this.state.colors[fieldName] || ''}/>

        <Button icon="pi pi-times"
                style={{
                  backgroundColor: defaultColor,
                  borderWidth: '1px 1px 1px 0',
                  borderColor: '#ddd'
                }}
                className="p-button-plain"
                onClick={() => {
                  let {colors} = this.state
                  delete colors[fieldName]
                  this.setState({colors})
                }}/>

      </div>
    </div>
  }

  /**
   * /COLORPICKER
   */

  render() {

    return (
      <React.Fragment>

        <OverlayPanel ref={this.colorPicker}>
          <SketchPicker
            color={this.pickerGetRgbFromString(this.state[this.state.colorField])}
            onChange={(e) => {
              const picked = this.pickerCalcState(e)
              let colors = {...this.state.colors}
              colors[picked.key] = picked.value
              this.setState({colors})
            }}/>
        </OverlayPanel>

        <div className="p-fluid" style={{marginTop: '1rem'}}>
          <DialogShowDeleteImage ref={this.DialogShowDeleteImage}/>
          <Fieldset legend="Thema en Media">
            <div className="p-field p-grid">
              <label htmlFor="local_tinycms_theme_id" className="p-col-3">Webshop theme</label>
              <div className="p-col-6">
                <Dropdown id="local_tinycms_theme_id"
                          value={this.state.local_tinycms_theme_id}
                          options={this.state.themes}
                          style={{width: '100%'}}
                          onChange={e => this.itemChanged(e)}
                />
              </div>
            </div>
            <div className="p-field p-grid">
              <label className="p-col-3">Afbeeldingen uploaden</label>
              <div className="p-col-6">
                <FileUpload name="images[]"
                            url={`${API}/tinycms-upload-images?webshop_id=${this.props.match.params.webshop_id}`}
                            multiple
                            auto={false}
                            accept="image/*;video/mp4"
                            onBeforeSend={this.onBeforeSend}
                            mode="advanced"
                            onUpload={() => {
                              this.getPartnerImages()
                            }}
                />
              </div>
            </div>
            <div className="p-field p-grid">
              <label className="p-col-3"/>
              <div className="p-col-6">
                {this.state.partner_images.map((imageName) => {

                  switch (imageName.split('.').pop()) {
                    case 'mp4':
                      return <video
                        key={imageName}
                        onClick={e => {
                          this.imgClicked(e)
                        }}
                        style={{...imgStyle, borderColor: '#ff6b30'}}>
                        <source src={STORAGE + '/' + imageName} type="video/mp4"/>
                      </video>
                    default:
                      return <img key={imageName} alt="" style={imgStyle} src={STORAGE + '/' + imageName}
                                  onClick={e => {
                                    this.imgClicked(e)
                                  }}/>
                  }

                })}
              </div>

            </div>
          </Fieldset>

          {/*====================================================================================*/}
          <Fieldset legend="Kleuren">
            {this.myColorPicker('mtg_color_1', 'MTG kleur één', '#99C430')}
            {this.myColorPicker('mtg_color_2', 'MTG kleur twéé', '#888788')}
            {this.myColorPicker('mtg_color_3', 'MTG kleur drie', '#5c7629')}
            {this.myColorPicker('mtg_color_4', 'MTG kleur vier', '#ebebeb')}

            <div className="p-grid">
              <div className="p-col-9">
                <hr style={{marginBottom: '0.8rem'}}/>
              </div>
            </div>

            {this.myColorPicker('white', 'De kleur wit', '#FFFFFF')}
            {this.myColorPicker('text_color', 'Text', '#333333')}
            {this.myColorPicker('text_color_secondary', 'Text Secondary', '#848484')}
            <div className="p-grid">
              <div className="p-col-9">
                <hr style={{marginBottom: '0.8rem'}}/>
              </div>
            </div>
            {this.myColorPicker('primary_color', 'Button', '#007ad9')}
            {this.myColorPicker('active', 'Button active', '#005b9f')}
            {this.myColorPicker('info', 'Button hover', '#116fbf')}
            {this.myColorPicker('focus', 'Element focus', '#8dcdff')}

            {this.myColorPicker('secondary', 'Secondary', '#607D8B')}
            {this.myColorPicker('success', 'Success', '#34A835')}

            {this.myColorPicker('warning', 'Warning', '#FBC02D')}
            {this.myColorPicker('help', 'Help', '#9C27B0')}
            {this.myColorPicker('danger', 'Danger', '#e91224')}
          </Fieldset>
          {/*========================================================================================*/}
          <Fieldset legend="Font">
            <div className="p-field p-grid">
              <label htmlFor="font_family" className="p-col-3">Font family</label>
              <div className="p-col-6">
                <InputText id="font_family" type="text" placeholder="'Raleway', sans-serif"
                           onChange={e => this.itemChanged(e)}
                           value={this.state.font_family || ''}/>
              </div>
            </div>

            <div className="p-field p-grid">
              <label htmlFor="font_family_link" className="p-col-3">Font family external link</label>
              <div className="p-col-6">
                <InputText id="font_family_link" type="text"
                           placeholder="https://fonts.googleapis.com/css?family=Raleway:400,700"
                           onChange={e => this.itemChanged(e)}
                           value={this.state.font_family_link || ''}/>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="Settings">
            {/*Radio buttons*/}
            <div className="p-field p-grid">
              <div className="p-col-3"></div>
              <label htmlFor="label_login_active" className="p-col-2">Login Aktief</label>
              <div className="p-col-1">
                <Checkbox inputId="label_login_active"
                          id="login_active"
                          onChange={e => this.itemChanged(e)}
                          checked={this.state.login_active ? true : false}/>
              </div>
              <label htmlFor="label_christmas_tree" className="p-col-2">Christmas tree</label>
              <div className="p-col-1">
                <Checkbox id="christmas_tree"
                          inputId="label_christmas_tree"
                          onChange={e => this.itemChanged(e)}
                          checked={this.state.christmas_tree}/>
              </div>

            </div>
            <div className="p-field p-grid">
              <div className="p-col-3"></div>
              <label htmlFor="label_no_price" className="p-col-2">Remove prices</label>
              <div className="p-col-1">
                <Checkbox id="no_price"
                          inputId="label_no_price"
                          onChange={e => this.itemChanged(e)} checked={this.state.no_price}/>
              </div>
              <label htmlFor="label_local_en_us" className="p-col-2">English</label>
              <div className="p-col-1">
                <Checkbox id="local_en_us"
                          inputId="label_local_en_us"
                          onChange={e => this.itemChanged(e)} checked={this.state.local_en_us}/>
              </div>
            </div>
          </Fieldset>
          <Fieldset legend="Opslaan">
            <div className="p-field p-grid">
              <div className="p-col-3"></div>

              <div className="p-col-5">
                <Button label="Save" onClick={() => this.save()}/>
              </div>
            </div>
          </Fieldset>

        </div>

      </React.Fragment>
    );
  }

  state = {
    webshop_id: 0,
    partner_id: 0,
    no_price: null,
    local_stockcontrol_off: null,
    local_en_us: null,
    local_tinycms_theme_id: 0,
    font_family: '',
    font_family_link: '',
    christmas_tree: null,
    portal_active: null,
    login_active: null,

    // Colors
    colors: {},

    colorField: null,
    partner_images: [],
    themes: []
  }

  constructor(props) {
    super(props);
    this.DialogShowDeleteImage = React.createRef()
    this.colorPicker = React.createRef()
  }

  imgClicked(e) {
    let src
    switch (e.target.tagName) {
      case 'VIDEO':
        src = e.target.firstChild.src
        break;
      case 'IMG':
        src = e.target.src
        break;
      default: //
    }

    this.DialogShowDeleteImage.current.open(src, e.target.tagName, (remove) => {
      if (remove) {

        // Fanyc code to ge the last two entries of the path (https://a/b/c/d/e.jpg => d/e.jpg)
        let file = src.split('/').slice(-2).join('/')

        axios.post(API + '/tinycms-remove-image', {file}).then(res => {
          this.getPartnerImages()
        })
      }
    })
  }

  onBeforeSend(req) {

    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + sessionStorage.getItem('api_token'));
    return req;
  }

  save() {
    let state = {...this.state}
    state.colors = JSON.stringify(state.colors)

    axios.post(API + '/tinycms-set-settings?webshop_id=' + this.props.match.params.webshop_id, state).then(res => {
      // @to msg ok or some
    })

  }

  componentDidMount() {
    this.getPartnerImages();
    // Themes
    axios.get(API + '/tinycms-get-themes').then(res => {
      let themes = res.data.map(d => {
        return {label: d.name, value: d.id}
      });
      this.setState({themes})
    })

    // Data
    axios.get(API + '/tinycms-get-settings?webshop_id=' + this.props.match.params.webshop_id).then(res => {

      let data = {}

      // transform some stuff
      for (let key in res.data) {

        if (this.state[key] !== undefined) {
          switch (key) {
            case 'no_price':
            case 'local_stockcontrol_off':
            case 'local_en_us':
            case 'christmas_tree':
            case 'portal_active':
            case 'login_active':
              data[key] = !!res.data[key]; // boolean
              break
            case 'colors':
              data[key] = JSON.parse(res.data[key] || '{}')
              break
            default:
              data[key] = res.data[key]
          }
        }
      }
      this.setState(data);

    })

  }

  getPartnerImages() {
    // Themes
    axios.get(API + '/tinycms-get-partner-images', {params: {webshop_id: this.props.match.params.webshop_id}}).then(res => {
      this.setState({partner_images: res.data})
    })
  }


  itemChanged(e) {
    let newState = {}
    switch (e.target.type) {
      case 'checkbox':
        newState[e.target.id] = e.target.checked
        break;
      default:
        newState[e.target.id] = e.target.value
        break;
    }
    this.setState(newState);
  }
}


export default Algemeen
