import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import axios from "axios";
import moment from 'moment'

export class Waiting extends Component {

  render() {

    return (
      <div>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col"/>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
            <strong>Last job Started:</strong> {this.state.job_started}
          </div>
          <div className="p-col-2">
            <strong>Job locked:</strong> {this.state.lock ? 'Yes' : 'No'}
          </div>

          <div className="p-col-3">
            {this.state.lock === 1 &&
            <div>
              <strong>Job running for:</strong> {this.state.runtime} <strong>seconds</strong>
            </div>
            }
          </div>

          <div className="p-col-12">
            {/*=======================================================*/}
            <DataTable paginator={true}
                       rows={15}
                       value={this.state.rows}
                       style={{marginTop: '5px'}}>

              <Column header="Webshop" field="display_name" style={{width: '40rem'}}/>
              <Column header="Voornaam" field="firstname"/>
              <Column header="Achternaam" field="lastname"/>
              <Column header="Besteldatum" field="write_date"/>

            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  state = {
    rows: [],
    job_started: '',
    lock: '',
    runtime: 0
  }

  componentDidMount() {

    this.getRows(true)
    // Check every minute
    this.intervalEveryMinute = setInterval(() => {
      this.getRows(false)
    }, 60000)

  }

  componentWillUnmount() {
    clearInterval(this.intervalEveryMinute);
    clearInterval(this.intervalEverySecond);

  }

  calcRuntime(job_started) {
    return Math.round((new Date().valueOf() - moment(job_started).valueOf()) / 1000);
  }

  getRows(runOnce) {
    axios.get(process.env.REACT_APP_MTG_BACK + '/report-nonsent-orders').then(response => {

      this.setState({
        rows: response.data.orders,
        job_started: response.data.cronlock.job_started,
        lock: response.data.cronlock.lock,
        runtime: this.calcRuntime(response.data.cronlock.job_started)
      }, () => {
        if (runOnce) {
          this.intervalEverySecond = setInterval(() => {
            this.setState({runtime: this.calcRuntime(this.state.job_started)})
          }, 1000)
        }
      })
    });
  }

}

export default Waiting
