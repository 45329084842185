import React from 'react'
import Menu from '../components/Menu'

import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";

import axios from "axios";
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Messages} from 'primereact/messages';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DialogVoucherOrders from "../components/dialogs/DialogVoucherOrders";
import {BlockUI} from "primereact/blockui";

const ODOO = process.env.REACT_APP_MTG_ODOO

class PageHelpdesk extends Auth {

  render() {
    return (
      <div>
        <DialogVoucherOrders ref={this.DialogVoucherOrders}/>
        <Menu {...this.props}/>
        <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Helpdesk">
          <div>
            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
              <TabPanel header="Zoek">
                <div className="p-grid" style={{marginTop: '0.5rem'}}>
                  <div className="p-col-6">
                    <div className="p-inputgroup">
                      <Button type="submit" label="Search" onClick={() => {
                        this.search()
                      }}/>
                      <InputText
                        placeholder="Order Nr, Postcode, Achternaam, Voucher Code E-mail of Odoo Id (Min 4 tekens)"
                        ref={this.searchRef}
                        value={this.state.search}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            this.search()
                          }
                        }}
                        onChange={(e) => this.setState({'search': e.target.value})}/>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6">
                    <Messages ref={(el) => this.messages = el} />
                  </div>
                </div>
                <BlockUI blocked={this.state.blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}} />}>
                <div className="p-grid" style={{marginTop: '-0.5rem'}}>
                  <div className="p-col-12">
                    <DataTable value={this.state.searchResult}
                               paginator={true}
                               rows={15}
                               scrollHeight="600px"
                               style={{marginTop: '5px'}}>
                      <Column field="id"
                              header="Order No" style={{width: '5rem'}}
                              body={(row) => <Button
                                style={{width: '100%'}}
                                label={'' + row.id}
                                onClick={() => this.DialogVoucherOrders.current.open(row, null, 'voucher')}
                              />}/>
                      <Column field="sale_id"
                              body={(row) => {
                                return row.local_shop_modus === 1 ?
                                  <div title="Deze order is op een kerstmarkt uitgegeven"
                                       style={{cursor: 'pointer', textAlign: 'center'}}>
                                    Winkel modus
                                  </div> :
                                  <a className="p-button p-button-warning"
                                     target="_blank"
                                     rel="noreferrer"
                                     style={{width: '100%', display: 'block', textAlign: 'center'}}
                                     href={`${ODOO}/web#id=${row.sale_id}&action=302&model=sale.order&view_type=form&cids=1&menu_id=755`}>
                                    {row.sale_id || <>&nbsp;</>}
                                  </a>
                              }}
                              header="Odoo Sale Id" style={{width: '6rem'}}
                      />

                      <Column field="username" header="Voucher" style={{width: '5rem'}}/>
                      <Column body={(row) => `${row.firstname} ${row.lastname}`}
                              header="Name" style={{width: '7rem'}}/>
                      <Column body={(row) => `${row.street} ${row.sc_street_number} ${row.street2 || ''}`}
                              header="Adres" style={{width: '14rem'}}/>
                      <Column field="zip" header="Postcode" style={{width: '4rem'}}/>
                      <Column field="city" header="Woonplaats" style={{width: '7rem'}}/>
                      <Column body={(row) => row.private_email || row.email} header="E-mail" style={{width: '15rem'}}/>
                      <Column field="webshop" header="Webshop" style={{width: '7rem'}}/>
                      <Column field="write_date" header="Order date" style={{width: '8rem'}}/>
                      <Column header="Track & Trace"
                              body={(row) => {
                                return row.track_trace !== '' ?
                                  <Button icon="pi pi-fw pi-link"
                                          style={{fontSize: '0.6rem'}}
                                          title={row.track_trace}
                                          onClick={(event) => window.open(row.track_trace, "_blank")}/>
                                  :
                                  null
                              }}
                              style={{width: '75px', textAlign: 'center'}}/>
                      <Column header="Verstuur email"
                              body={(row) => {
                                return (
                                    <Button icon="pi pi-fw pi-envelope"
                                            style={{fontSize: '0.6rem'}}
                                            onClick={this.resendEmail.bind(this, row)}/>
                                )}}
                              style={{width: '75px', textAlign: 'center'}}/>
                    </DataTable>
                  </div>
                </div>
                </BlockUI>
              </TabPanel>
            </TabView>

          </div>
        </Fieldset>
      </div>
    )
  }

  state = {
    activeIndex: 0,
    blockedPanel: false,
    search: '',
    searchResult: []
  }

  constructor(props) {
    super(props);
    this.searchRef = React.createRef()
    this.DialogVoucherOrders = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.searchRef.current.focus()
    }, 0);
    super.componentDidMount()
  }

  search() {
    if (this.state.search.length < 4) {
      this.messages.show({severity: 'info', summary: 'Minimaal vier (4) tekens....', life: 1500, closable: false});
      return
    }
    this.setState({blockedPanel: true})
    axios.get(process.env.REACT_APP_MTG_BACK + '/helpdesk/search', {params: {search: this.state.search}}).then((res) => {
      this.setState({searchResult: res.data})
      this.setState({blockedPanel: false})
    }).catch(error => {
      console.log(error);
      this.messages.show({severity: 'error', summary: 'De zoekresultaten konden niet opgehaald worden : '+error, closable: true});
      this.setState({blockedPanel: false})
    });

  }

  resendEmail(row) {
    this.setState({blockedPanel: true})
    axios.post(process.env.REACT_APP_MTG_BACK + '/helpdesk/resend-email', {id: row.id}).then(response => {
      this.setState({blockedPanel: false})
      if (response.data.msg === 'OK') {
        this.messages.show({severity: 'success', summary: 'De e-mail is opnieuw verzonden.', life: 3500, closable: false});
      }
      else {
        this.messages.show({severity: 'error', summary: 'De email kon niet verzonden worden : '+JSON.stringify(response.data), sticky:true, closable: true});
      }

    }).catch(error => {
      console.log(error);
      this.messages.show({severity: 'error', summary: 'De email kon niet verzonden worden : '+error, closable: true});
      this.setState({blockedPanel: false})
    });
  }
}

export default PageHelpdesk
