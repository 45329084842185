import React, {useEffect, useState} from 'react'

const UserContext = React.createContext({});

const UserContextProvider = ({children}) => {

  const [userName, setUserName] = useState('')
  const [userRoles, setUserRoles] = useState([])

  const hasRole = (roleName) => {
    return userRoles.includes(roleName)
  }
  /**
   * Set the default Hoofdcat and set Min max price range
   */
  useEffect(() => {

  }, [])

  return <UserContext.Provider value={{
    userName,userRoles,setUserName,setUserRoles,hasRole
  }}>
    {children}
  </UserContext.Provider>
}

export {UserContext, UserContextProvider}
