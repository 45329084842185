import React, {Component} from 'react'

import {Dialog} from 'primereact/dialog';

// const PATH = process.env.REACT_APP_MTG_WEBSHOP
const PATH = process.env.REACT_APP_MTG_STOR
class DialogImagePreview extends Component {

  render() {
    return <Dialog
      header="Image preview"
      visible={this.state.visible}
      style={{width: '75rem'}}
      position={'top'}
      modal={true}
      onHide={() => {
        this.setState({visible: false})
      }}>
      <div className="p-grid">
        <div className="p-col-4" style={{textAlign: 'center', border: 'solid gainsboro', borderWidth: '1px 0 1px 1px'}}>
          <h3>Orgineel</h3>
          <img alt="" style={{width: '316px',border:'solid 1px gainsboro'}} src={`${PATH}/img/odoo-producten/1170x1170/${this.state.image_id}`}/>
        </div>
        <div className="p-col-4" style={{textAlign: 'center', border: 'solid 1px gainsboro'}}>
          <h3>Als ambience</h3>
          <img alt="" style={{width: '316px',border:'solid 1px gainsboro'}} src={`${PATH}/img/odoo-producten/1170x555/${this.state.image_id}`}/>
        </div>
        <div className="p-col-4"
             style={{textAlign: 'center', border: 'solid gainsboro', borderWidth: '1px 1px 1px 0px'}}>
          <h3>Als product</h3>
          <img alt="" style={{width: '150px',border:'solid 1px gainsboro' }} src={`${PATH}/img/odoo-producten/250x250/${this.state.image_id}`}/>
        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    image_id: null
  }

  constructor(props) {
    super(props);
    this.onsync = this.onsync.bind(this)
  }

  onsync() {
    this.setState({visible: false})
  }

  open(image_id) {
    this.setState({
      visible: true,
      image_id,
    })
  }
}

export default DialogImagePreview
