const options = [
  {label: 'Leeg', value: 0},
  {label: 'Binnenland exclusief BTW', value: 1},
  {label: 'EU landen', value: 3},
  {label: 'Niet-EU landen', value: 4},
  {label: 'Binnenland inclusief BTW', value: 5},
  {label: 'Niet van toepassing', value: 11},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

const products = [
  {label: 'Leeg', value: 0},
  {label: '0%', value: 1},
  {label: '21%', value: 3},
  {label: '9%', value: 78},
]

const lookupProduct = (value) => {
  let i = products.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}
export  {products, lookupProduct}

