import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {AutoComplete} from 'primereact/autocomplete';
import {Checkbox} from 'primereact/checkbox';
import {Fieldset} from 'primereact/fieldset';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button'
import DialogAlert from '../dialogs/DialogAlert'
import DialogAreYouSure from '../dialogs/DialogAreYouSure'
import moment from 'moment'
import axios from 'axios'
import {InputText} from "primereact/inputtext";
import DownloadWebshops from '../download/DownloadWebshops';

const WEBSHOP = process.env.REACT_APP_MTG_WEBSHOP

export class Webshops extends Component {

  render() {
    let style = {
      iconStyle: {
        width: '1.2rem',
        textAlign: 'center',
        padding: 0,
        height: '2.5rem'
      },
      productFilter: {
        backgroundColor: 'white',
        float: 'right',
        marginTop: '-2.3rem',
        padding: '0 0.8rem',
        color: '#c8c8c8'
      },
      col: {
        height: '2.5rem', padding: '0.3rem'
      },
    }

    let myFilter = <div className="p-inputgroup" style={{fontSize: '0.8rem', display: this.state.filterDisplay}}>
      <Button
        className="left-input"
        icon="pi pi-filter"
        style={{
          backgroundColor: this.state.filterOn ? '#34a835' : '#eaeaea',
          color: this.state.filterOn ? '#ddd' : '#848484'
        }}/>

      <AutoComplete
        inputStyle={{borderWidth: '1px 1px 1px 0'}}
        style={{width: '90%'}}
        value={this.state.filterTextAutocomplete}
        suggestions={this.state.filterSuggestions}
        completeMethod={this.filterComplete}
        itemTemplate={(e) => {
          return e.display_name
        }}
        field="name"
        size={50}
        placeholder={this.state.filterPlaceholder}
        minLength={3}
        maxlength={15}
        onClear={() => {
          this.uxChange('filterAutocomplete', 'clear')
        }}
        onChange={(e) => {
          // Seems like this is the only way to get a selected
          if (typeof e.value === 'object') {
            this.uxChange('filterAutocomplete', 'selected', e.value)
          } else {
            this.setState({filterTextAutocomplete: e.value})   // Bind
          }
        }}/>
    </div>
    let mySearch = <div className="p-inputgroup" style={{fontSize: '0.8rem', display: this.state.searchDisplay}}>
      <Button
        className="left-input"
        icon="pi pi-plus-circle"
        style={{
          backgroundColor: this.state.searchOn ? '#ffba01' : '#eaeaea',
          color: this.state.searchOn ? '#333' : '#848484'
        }}/>


      <AutoComplete ref={this.SearchAutocomplete}
                    inputStyle={{borderWidth: '1px 1px 1px 0'}}
                    style={{width: '90%'}}
                    value={this.state.searchTextAutocomplete}
                    suggestions={this.state.searchSuggestions}
                    completeMethod={this.searchComplete}
                    itemTemplate={(e) => {
                      return e.display_name
                    }}
                    autoFocus={true}
                    field="name"
                    size={50}
                    placeholder={this.state.searchPlaceholder}
                    minLength={3}
                    maxlength={15}
                    onClear={() => {
                      this.uxChange('searchAutocomplete', 'clear')
                    }}
                    onChange={(e) => {
                      if (typeof e.value === 'object') {
                        this.uxChange('searchAutocomplete', 'select', e.value)
                      } else {
                        this.setState({searchTextAutocomplete: e.value})
                      }
                    }}/>
    </div>
    let myReprice = <div className="p-inputgroup" style={{fontSize: '0.8rem', display: this.state.repriceDisplay}}>
      <InputText style={{width: '17rem'}}
                 placeholder="Herprijs in % (100% = default prijs)"
                 value={this.state.repriceValue}
                 onChange={(e) => this.setState({repriceValue: e.target.value})}
      />
    </div>

    return (
      <div>
        <DialogAlert ref={this.DialogAlert}/>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
       
        <Fieldset legend="Webshops" style={{fontSize: '1.3rem'}}>
          <div className="p-grid" style={{padding: '4px 8px', marginBottom: '1.5rem'}}>
            <div className="p-col-2" style={{padding: 0}}>
              <DownloadWebshops/>
            </div>
          </div>
          <div className="p-grid" style={{padding: '4px 8px', marginTop: '-1rem'}}>

            {/* ===========================  Dropdown  ==================================== */}
            <div className="p-col-3" style={{padding: 0}}>
              <Dropdown style={{width: '90%'}}
                        value={this.state.actionSelected}
                        optionLabel="val"
                        optionValue="key"
                        options={this.state.actionOptions}
                        onChange={(e) => {
                          this.setState({actionSelected: e.value}) // The bind part
                          this.uxChange('dropdownAction', 'selected', e.value)
                        }}
                        placeholder="Kies een actie..."/>
            </div>


            <div className="p-col-4" style={{padding: 0}}>
              {/* ===========================  Filter or Search ============================ */}
              {this.state.actionSelected === 'add_prod' ? mySearch : myFilter}
            </div>

            <div className="p-col-4" style={{padding: 0}}>
              {/* ===========================  Filter or Search  =========================== */}
              {this.state.actionSelected === 'add_prod' ? myFilter : mySearch}
              {/* ===========================  Reprice  ==================================== */}
              {myReprice}
            </div>
           

            {/* ===========================  Main Button  ==================================== */}
            <div className="p-col-1 p-text-center" style={{padding: 0, visibility: this.state.buttonVisibility}}>
              <Button label="GO!"
                      onClick={() => this.goButtonClicked()}
                      className="p-button-warning"
                      style={{width: '50%'}}/>
            </div>
          </div>
          {/* ========================== Data table ===================================== */}
          <div>
            <DataTable value={this.state.rows}
                       ref={this.DataTableRef}
                       onValueChange={filteredData => this.setState({filteredRows: filteredData})}
                       editable={true}
                       scrollable={false}
                       scrollHeight="600px"
                       removableSort
                       paginator
                       rows={500}
                       style={{marginTop: '5px'}}>
              {/* ============================================================================================ */}
              <Column field="type"
                      header=""
                      style={{
                        padding: 0,
                        width: '1.2rem',
                        textAlign: 'center',
                        color: '#666'
                      }}
                      body={row => {
                        switch (row.type) {
                          case 'webshop':
                            return <i className="pi pi-shopping-cart" style={{fontSize: '1.3rem'}}/>
                          case 'kerstmarkt':
                            return <i className="pi pi-angle-double-up" style={{fontSize: '1.3rem'}}/>
                          case 'vm':
                            return <i className="pi pi-video" style={{fontSize: '1.3rem'}}/>
                          default:
                            return null
                        }
                      }
                      }
              />
              <Column body={(row) => {
                return <Link to={`/webshop/${row.id}`}>
                  <i className="pi pi-fw pi-sitemap" style={{fontSize: '1.5em'}}></i>
                </Link>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column body={(row) => {
                return <Link to={`/vouchers/${row.id}`}>
                  <i className="pi pi-fw pi-key" style={{fontSize: '1.5em'}}></i>
                </Link>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column body={row => {
                return <Link to={`/webshop-properties/${row.id}`}>
                  <i className="pi pi-fw pi-pencil" style={{fontSize: '1.3em'}}/>
                </Link>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column body={row => {
                return <Link to={`/virtual-markets/${row.id}`}>
                  <i className="pi pi-fw pi-video" style={{fontSize: '1.3em'}}/>
                </Link>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column body={row => {
                return <Link to={`/tiny-cms/${row.id}`}>
                  <i className="pi pf-fw pi-palette" style={{fontSize: '1.3em'}}/>
                </Link>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column body={row => {
                return <a target="_blank" rel="noopener noreferrer"
                          href={`${WEBSHOP}/mtg-login/#/${row.id}/${this.api_token}`}>
                  <i className="pi pi-fw pi-external-link"
                     title="Edit the webshop..."
                     style={{fontSize: '1.3em'}}/>
                </a>
              }} style={style.iconStyle}/>
              {/* ============================================================================================ */}
              <Column sortable
                      filter
                      filterPlaceholder="Zoek op naam"
                      filterMatchMode="contains"
                      field="display_name"
                      body={row => {
                        return <Link to={`/webshop/${row.id}`}>
                          {row.display_name}
                        </Link>
                      }} header="Webshop Naam" style={{width: '20rem'}}/>
              <Column header={<span title="Too expensive counter">$</span>}
                      sortable
                      field="count_too_expensive_products"
                      className="t-center"
                      style={{width: '25px', textAlign: 'center'}}
                      body={row => <span className={row.count_too_expensive_products > 0 ? 't-red-strong' : ''}>
                        {row.count_too_expensive_products ? row.count_too_expensive_products : ''}
                      </span>}/>
              <Column field="euro_amount"
                      filter
                      body={(row) => '€ ' + row.euro_amount}
                      header="Euro's" style={{...style, width: '50px', textAlign: 'right'}}/>
              <Column field="voucher_amount" filter header="Eco's"
                      style={{...style, width: '35px', textAlign: 'right'}}/>
              <Column field="total_vouchers_unused" header="Vouchers ongebruikt"
                      sortable
                      style={{...style, width: '50px', textAlign: 'right'}}/>
              <Column field="total_vouchers_used" header="Vouchers gebruikt"
                      sortable
                      style={{...style, width: '50px', textAlign: 'right'}}/>
              <Column field="start_date"
                      body={row => <span title={row.start_date}>{moment(row.start_date).format('YYYY-MM-DD')}</span>}
                      sortable
                      header="Start"
                      style={{...style, width: '40px', textAlign: 'center'}}/>
              <Column field="end_date"
                      body={row => <span title={row.end_date}>{moment(row.end_date).format('YYYY-MM-DD')}</span>}
                      sortable header="End"
                      style={{...style, width: '40px', textAlign: 'center'}}/>
              <Column
                field="product_ids"
                header={
                  <Checkbox
                    checked={this.state.checkboxAllChecked}
                    onMouseDown={() => {
                      this.allChecked()
                    }}
                    style={{marginLeft: '-0.26rem'}}/>
                }
                body={(row) =>
                  <Checkbox
                    checked={row.checked}
                    onMouseDown={() => {
                      this.rowChecked(row)
                    }}
                  />
                }
                style={{...style, width: '1.35rem', textAlign: 'center'}}/>
            </DataTable>
          </div>

        </Fieldset>
      </div>

    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  state = {
    // Data
    rows: [],
    filteredRows: [],
    searchProds: [],
    filterProds: [],
    // Filter
    filterTextAutocomplete: '',
    filterOnThisProduct: null,
    filterSuggestions: null,
    filterDisplay: 'none',
    filterPlaceholder: '',
    filterOn: false,
    // Search
    searchTextAutocomplete: '',
    searchOnThisProduct: null,
    searchSuggestions: null,
    searchDisplay: 'none', // css
    searchPlaceholder: '',
    searchOn: false,
    // Reprice
    repriceDisplay: 'none',
    repriceValue: '',

    // Checkboxes
    checkboxAllChecked: false,
    // Actions
    actionOptions: [
      {key: 'search_prod', val: 'Zoek op product'},
      {key: 'replace_prod', val: 'Vervang een product'},
      {key: 'add_prod', val: 'Voeg een product toe'},
      {key: 'remove_prod', val: 'Verwijder een product'},
      {key: 'reprice_prod', val: 'Herprijs een product'},
    ],
    actionSelected: null,
    buttonVisibility: 'hidden',
    canSubmit: false

  }

  getHtmlTableForRepricePopup(res) {
    return <table style={{width: '100%'}}>
      <tbody>
      <tr>
        <th></th>
        <th style={{textAlign: 'right'}}>Default</th>
        <th style={{textAlign: 'right'}}>Huidig</th>
        <th style={{textAlign: 'right'}}>Nieuw</th>
      </tr>
      {res.data.repriced.map((rst, i) => <tr key={i}>
        <td>{rst.webshop_name}</td>
        <td style={{textAlign: 'right'}}>{rst.default_price}</td>
        <td style={{textAlign: 'right'}}>{rst.old_price}</td>
        <td style={{textAlign: 'right'}}>{rst.new_price}</td>
      </tr>)}
      </tbody>
    </table>

  }

  getHtmlTableForAddProduct(res) {
    return <div className='p-datatable p-component'>
      <div className='p-datatable-wrapper'>
      <table style={{width: 'auto'}}>
        <thead className='p-datatable-thead'>
          <tr>
            <th>Webshop ID</th>
            <th>Webshop naam</th>
            <th>Webshop euro’s</th>
            <th title='Product standard price' style={{textAlign: 'right'}}>PSP</th>
            <th title='Product internet price' style={{textAlign: 'right'}}>PIP</th>
            <th style={{textAlign: 'right'}}>ECO's</th>
          </tr>
        </thead>
        <tbody className='p-datatable-tbody'>
          {res.data.webshops.map((rst, i) => <tr key={i}>
            <td>{rst.webshop_id}</td>
            <td>{rst.webshop_name}</td>
            <td>{rst.webshop_amount}</td>
            <td style={{textAlign: 'right'}}>{rst.product_standard_price.replace(".", ",")}</td>
            <td style={{textAlign: 'right'}}>{rst.product_internet_price.replace(".", ",")}</td>
            <td style={{textAlign: 'right'}}>{rst.eco}</td>
          </tr>)}
        </tbody>
      </table>
      </div>
    </div>

  }

  goButtonClicked() {
    // get all checked rows
    let checkedWebshopIds = [];
    this.state.rows.filter((item) => {
      return (item.checked === true)
    }).forEach((item) => {
      checkedWebshopIds.push(item.id)
    })

    switch (this.state.actionSelected) {

      case 'reprice_prod':
        let data = {
          webshop_ids: checkedWebshopIds,
          find_id: this.state.filterOnThisProduct.id,
          perc: this.state.repriceValue,
          dryrun: true
        }
        // We do a POST with the dryrun flag set
        axios.post(process.env.REACT_APP_MTG_BACK + '/reprice-product-in-webshops', data).then(res => {
          this.DialogAreYouSure.current.open(
            <div>{`Je staat op het punt onderstaande prijswijzigingen door te voeren.\nWeet je dit zeker?\n\n`}
              {this.getHtmlTableForRepricePopup(res)}
            </div>,
            () => {
              // Now when OK clicked in te AreYouSure popup set dryrun to false and POST again
              data.dryrun = false
              axios.post(process.env.REACT_APP_MTG_BACK + '/reprice-product-in-webshops', data).then(res => {
                this.DialogAlert.current.open(
                  <div>Prijs <strong>{this.state.filterTextAutocomplete}</strong> aangepast
                    in {res.data.repriced} webshops!</div>
                  , () => {
                    this.allClear()
                  }, '', 'top', '60em')
              })
            },
            this.state.filterTextAutocomplete, 'top', '60em')
        })
        break

      case 'replace_prod':
        axios.post(process.env.REACT_APP_MTG_BACK + '/replace-product-in-webshops',
          {
            webshop_ids: checkedWebshopIds,
            find_id: this.state.filterOnThisProduct.id,
            replace_id: this.state.searchOnThisProduct.id
          }
        ).then(res => {
          this.DialogAlert.current.open('Done! Toegevoegd: ' + res.data.added + ' Verwijderd: ' + res.data.removed, () => {
            this.allClear()
          })
        })
        break
      case 'add_prod':
        // We do a POST with the dryrun flag set
        let add_data = {
          dryrun: true,
          webshop_ids: checkedWebshopIds,
          product_id: this.state.searchOnThisProduct.id
        }
        axios.post(process.env.REACT_APP_MTG_BACK + '/add-product-to-webshops', add_data).then(res => {

          if (res.data.status === 'error') {
            this.DialogAlert.current.open('Product foutmelding: ' + res.data.msg, () => {
              this.allClear()
            })
          } else {
            this.DialogAreYouSure.current.open(
              <div>{`Je staat op het punt onderstaande product toe tevoegen.\nWeet je dit zeker?\n\n`}
                <h3>Product: {add_data.product_id}</h3>
                {this.getHtmlTableForAddProduct(res)}
              </div>,
              () => {
                // Now when OK clicked in te AreYouSure popup set dryrun to false and POST again
                add_data.dryrun = false
                axios.post(process.env.REACT_APP_MTG_BACK + '/add-product-to-webshops', add_data).then(res => {
                  this.DialogAlert.current.open('Done! Toegevoegd: ' + res.data.added, () => {
                    this.allClear()
                  })
                })
              },
              this.state.filterTextAutocomplete, 'top', '60em')
          }
        })
        break
      case 'remove_prod':
        axios.post(process.env.REACT_APP_MTG_BACK + '/remove-product-from-webshops',
          {
            webshop_ids: checkedWebshopIds,
            product_id: this.state.filterOnThisProduct.id
          }
        ).then(res => {
          this.DialogAlert.current.open('Done! Verwijderd: ' + res.data.removed, () => {
            this.allClear()
          })
        })
        break
      default:
    }
  }


  constructor(props) {
    super(props)
    this.api_token = sessionStorage.getItem('api_token');

    this.SearchAutocomplete = React.createRef()
    this.DialogAlert = React.createRef()
    this.DataTableRef = React.createRef()
    this.DialogAreYouSure = React.createRef()
    this.filterComplete = this.filterComplete.bind(this)
    this.searchComplete = this.searchComplete.bind(this)
  }


  allClear() {
    this.getWebshops()
    this.getProducts()
    this.allFiltersClear()
    this.setState({
        actionSelected: null,
        buttonVisibility: 'none',
        filterDisplay: 'none',
        searchDisplay: 'none',
        repriceDisplay: 'none',
        canSubmit: false,
        repriceValue: ''
      }
    )

  }

  allFiltersClear() {
    this.filterClear()
    this.searchClear()
    this.allChecked(false)
  }

  filterClear() {
    this.DataTableRef.current.filter('', 'product_ids', 'contains')
    this.setState({
      filterTextAutocomplete: '',
      filterOnThisProduct: null,
      filterSuggestions: null,
      filterOn: false,
    })
  }

  searchClear() {
    this.setState({
      searchTextAutocomplete: '',
      searchOnThisProduct: null,
      searchSuggestions: null,
      searchOn: false,
    })
  }

  showButton(onOrOff) {
    this.setState({buttonVisibility: onOrOff ? 'visible' : 'hidden'})
  }

  uxChange(element, action, data) {

    switch (element) {

      // ========================================================================
      case 'rowChecked':
        this.showButton(this.getCountRowsChecked() && this.state.canSubmit)
        break
      // ========================================================================
      case 'filterAutocomplete':
        switch (action) {
          case 'clear':
            this.allChecked(false)
            this.filterClear()
            switch (this.state.actionSelected) {
              case 'replace_prod':
              case 'remove_prod':
              case 'reprice_prod':
                this.setState({
                  canSubmit: false,
                  repriceDisplay: 'none',
                  repriceValue: ''
                })
                this.showButton(false)
                break
              default:
            }
            break
          case 'selected':
            // Filter on product
            this.DataTableRef.current.filter('|' + data.id + '|', 'product_ids', 'contains')

            switch (this.state.actionSelected) {
              case 'reprice_prod':
                this.setState({
                  repriceDisplay: 'block',
                  canSubmit: true
                })
                break;
              case 'replace_prod':
                let canSubmit = this.state.searchOnThisProduct ? true : false
                this.setState({canSubmit})
                break
              case 'remove_prod':
                this.setState({canSubmit: true})
                break
              default:
            }

            this.setState({
              filterOnThisProduct: data,
              filterTextAutocomplete: data.display_name,
              filterOn: true
            })
            break
          default:
        }
        break
      // ========================================================================
      case 'searchAutocomplete':
        switch (action) {
          case 'clear':
            this.searchClear()
            switch (this.state.actionSelected) {
              case 'replace_prod':
              case 'add_prod':
                this.setState({canSubmit: false})
                this.showButton(false)
                this.allChecked(false)
                break
              default:
            }
            break
          case 'select':
            switch (this.state.actionSelected) {
              case 'replace_prod':
                let canSubmit = this.state.filterOnThisProduct ? true : false
                this.setState({canSubmit})
                break
              case 'add_prod':
                this.setState({canSubmit: true})
                break
              default:
            }

            this.setState({
              searchOnThisProduct: data,
              searchTextAutocomplete: data.display_name,
              searchOn: true,
            })
            break
          default:
        }
        break
      // ========================================================================
      case 'dropdownAction':
        switch (action) {
          case 'selected':
            // First reset all
            this.allFiltersClear()
            switch (data) {
              case 'reprice_prod':
                this.setState({
                  filterDisplay: 'block',
                  // repriceDisplay: 'block', // Visible when prod selected
                  filterPlaceholder: 'Zoek een product..',
                  searchDisplay: 'none'
                })

                break;
              case 'search_prod':
                this.setState({
                  filterDisplay: 'block',
                  filterPlaceholder: 'Zoek een product..',
                  searchDisplay: 'none',
                  repriceDisplay: 'none'
                })
                break
              case 'replace_prod':
                this.setState({
                  filterDisplay: 'block',
                  filterPlaceholder: 'Vervang dit product..',
                  searchDisplay: 'block',
                  repriceDisplay: 'none',
                  searchPlaceholder: 'met dit product..'
                })
                break
              case 'add_prod':
                this.setState({
                  filterDisplay: 'block',
                  searchPlaceholder: 'Voeg dit product toe..',
                  searchDisplay: 'block',
                  repriceDisplay: 'none',
                  filterPlaceholder: 'Filter eventueel op dit product..'
                })
                break
              case 'remove_prod':
                this.setState({
                  filterDisplay: 'block',
                  filterPlaceholder: 'Verwijder dit product..',
                  searchDisplay: 'none',
                  repriceDisplay: 'none'
                })
                break
              default:
            }
            break
          default:
        }
        break;
      default:
    }
  }


  /**
   * ROWS
   */
  getCountRowsChecked() {
    return this.state.rows.filter((i) => {
      return i.checked
    }).length
  }

  rowChecked(row) {

    let newRow = this.state.rows.filter((i) => {
      return i.id === row.id
    })[0];
    newRow.checked = !newRow.checked
    this.setState({rows: this.state.rows}, () => {
      this.uxChange('rowChecked')
    })
  }

  allChecked(forceTo) {
    // Set to the forceTo param or toggle
    let checked = typeof forceTo !== 'undefined' ? forceTo : !this.state.checkboxAllChecked

    if (checked === false) {
      // Simply uncheck all
      this.state.rows.forEach(row => row.checked = false)
    } else {
      // We have a filter active. Only check the filtered rows
      this.state.filteredRows.forEach(filteredRow => {
        // Find the row by id
        let row = this.state.rows.filter((i) => {
          return i.id === filteredRow.id
        })[0];
        row.checked = true;
      })
    }

    this.setState({
      rows: this.state.rows,
      checkboxAllChecked: checked
    }, () => {
      this.uxChange('rowChecked')
    })

  }

  searchComplete(e) {
    setTimeout(() => {
      let res = this.state.searchProds.filter((prod) => {
        return prod.display_name.toLowerCase().includes(e.query.toLowerCase());
      });
      let sugs = res.map((e) => {
        return {id: e.id, display_name: e.display_name};
      })
      this.setState({searchSuggestions: sugs});
    }, 100);
  }

  /**
   * FILTER
   */
  filterComplete(e) {
    setTimeout(() => {
      let res = this.state.filterProds.filter((prod) => {
        return prod.display_name.toLowerCase().includes(e.query.toLowerCase());
      });
      let sugs = res.map((e) => {
        return {id: e.id, display_name: e.display_name};
      })
      this.setState({filterSuggestions: sugs});
    }, 100);
  }

  /**
   *
   */
  componentDidMount() {
    this.getWebshops();
    // You need the prods for the autocompletes and mass adding etc...
    this.getProducts();

  }

  /**
   *
   */
  getWebshops() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshops').then(res => {
      this.setState({
        rows: res.data,
        filteredRows: res.data // !! need this because afterFilter also fills this state prop !! //
      })
    });
  }

  /**
   *
   */
  getProducts() {

    // For the filter
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshop-products').then(res => {
      res.data.forEach((item) => {
        item.display_name = `[${item.id.toString().padStart(5, '0')}] ${item.display_name}`
      })
      this.setState({filterProds: res.data})
    });

    // To add new products
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-products').then(res => {
      res.data.forEach((item) => {
        item.display_name = `[${item.id.toString().padStart(5, '0')}] ${item.display_name}`
      })
      this.setState({searchProds: res.data})
    });

  }

}

export default Webshops
