import React, {Component} from 'react'
import {Button} from "primereact/button";
import {FileService} from "../../services/file-service";
import { saveAs } from 'file-saver';

export class Music extends Component {
    render() {
        return (
                <div className="p-fluid" style={{marginTop: '1rem'}}>
                    <div className="p-field p-grid">
                        <div className="p-col-3">
                            <Button label="Download uitslag poll" icon="pi pi-cloud-download"
                                    onClick={() => this.downloadFile()}
                                    style={{margin: '0 2px'}}
                            />
                        </div>
                    </div>
                </div>
        )
    }

    constructor(props) {
        super(props);
        this.fileService = new FileService();
        this.state={downloading:false};
    }

    downloadFile(type) {

        this.setState({ downloading: true });

        let self = this;

        this.fileService.getMusicPollFromServer(this.props.webshop, type).then((response) => {

            this.setState({ downloading: false});

            //extract file name from Content-Disposition header not possible
            saveAs(response.data, this.props.webshop.slug + '-musicpoll.xlsx');

        }).catch(function (error) {

            self.setState({ downloading: false });
            if (error.response) {
                console.log('Error', error.response.status);
            } else {
                console.log('Error', error.message);
            }
        });
    };
}

export default Music
