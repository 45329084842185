import React, { Component } from 'react';
import { FileService } from '../../services/file-service';
import { Button } from 'primereact/button';
import { saveAs } from 'file-saver';
import { ProgressSpinner } from 'primereact/progressspinner';

class DownloadWebshops extends Component {

  render() {
    if (this.state.downloading) return <ProgressSpinner/>;
    return (
      <Button
        className="p-button-primary"
        icon="pi pi-cloud-download"
        onClick={()=>this.downloadFile()}
        style={{
          position: 'relative',
          fontSize: '1rem',
          width: '8em'
        }}> <span style={{marginLeft: '0.5rem'}}>Download</span></Button>

    )
  };


  constructor(props) {
    super(props);
    this.fileService = new FileService();
    this.state={downloading:false};
  }


  downloadFile = () => {

    this.setState({ downloading: true });

    let self = this;

    this.fileService.getWebshopsFromServer().then((response) => {

      this.setState({ downloading: false});

      //extract file name from Content-Disposition header not possible
      saveAs(response.data, 'webshops.xlsx');


    }).catch(function (error) {
      self.setState({ downloading: false });
      if (error.response) {
        console.log('Error', error.response.status);
      } else {
        console.log('Error', error.message);
      }
    });
  };


}
export default DownloadWebshops
