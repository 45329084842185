import React, {Component} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/nova/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'


import './App.css'

import PageLogin from './pages/PageLogin'

import PageWebshop from './pages/PageWebshop'
import PageWebshops from './pages/PageWebshops'
import PagePartners from './pages/PagePartners'
import PageVouchers from './pages/PageVouchers'
import PageTinyCms from './pages/PageTinyCms'
import PageProducts from './pages/PageProducts'
import PageSettings from './pages/PageSettings'
import PageWebshopProperties from './pages/PageWebshopProperties'
import PageVirtualMarkets from './pages/PageVirtualMarkets'
import PageReports from './pages/PageReports'
import PageTypeform from './pages/PageTypeform'
import PageHelpdesk from './pages/PageHelpdesk'
import PageUsers from './pages/PageUsers'
import PageClientUsers from "./pages/PageClientUsers";
import PageOrders from './pages/PageOrders';
import {UserContextProvider} from "./contexts/UserContext";


class App extends Component {
  render() {
    return (
        <div className="App p-grid" style={{maxWidth: '80%', margin: 'auto'}}>
          <div className="p-col">
            <UserContextProvider>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Switch>
                  <Route key="01" exact path="/" render={(props) => <PageLogin {...props}/>}/>
                  <Route key="03" exact path="/webshop/:webshop_id" render={(props) => <PageWebshop {...props}/>}/>
                  <Route key="04" exact path="/products" render={(props) => <PageProducts {...props}/>}/>
                  <Route key="05" exact path="/webshops/:product_id?" render={(props) => <PageWebshops {...props}/>}/>
                  <Route key="06" exact path="/partners" render={(props) => <PagePartners {...props}/>}/>
                  <Route key="07" exact path="/vouchers/:webshop_id" render={(props) => <PageVouchers {...props}/>}/>
                  <Route key="08" exact path="/webshop-properties/:webshop_id"
                         render={(props) => <PageWebshopProperties {...props}/>}/>
                  <Route key="10" exact path="/virtual-markets/:webshop_id"
                         render={(props) => <PageVirtualMarkets {...props}/>}/>
                  <Route key="11" exact path="/tiny-cms/:webshop_id" render={(props) => <PageTinyCms {...props}/>}/>
                  <Route key="12" exact path="/reports/:report_name?/:id?" render={(props) => <PageReports {...props}/>}/>
                  <Route key="13" exact path="/typeform" render={(props) => <PageTypeform {...props}/>}/>
                  <Route key="14" exact path="/helpdesk" render={(props) => <PageHelpdesk {...props}/>}/>
                  <Route key="15" exact path="/settings" render={(props) => <PageSettings {...props}/>}/>
                  <Route key="16" exact path="/users" render={(props) => <PageUsers {...props}/>}/>
                  <Route key="17" exact path="/client-users" render={(props) => <PageClientUsers {...props}/>}/>
                  <Route key="16" exact path="/orders" render={(props) => <PageOrders {...props}/>}/>
                </Switch>
              </BrowserRouter>
            </UserContextProvider>
          </div>
        </div>
    );
  }
}

export default App;
