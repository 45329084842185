import React, {Component} from 'react'
import {TabView, TabPanel} from 'primereact/tabview';
import Algemeen from './Algemeen'
import Portal from './Portal'
import Poll from './Poll'

export class Index extends Component {

  render() {

    return (
      <div>
        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
          <TabPanel header="Algemeen">
            <Algemeen {...this.props}/>
          </TabPanel>
          <TabPanel header="Portal / Muziek">
            <Portal {...this.props}/>
          </TabPanel>
          <TabPanel header="Stembus">
            <Poll {...this.props}/>
          </TabPanel>
        </TabView>

      </div>
    );
  }


  state = {
    activeIndex: 0,
  }

}

export default Index
