import React, {Component} from 'react'
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";

const BE = process.env.REACT_APP_MTG_BACK

class Ribbons extends Component {

  render() {
    return <div style={{width: '400px'}}>
      <DataTable value={this.state.ribbons} editMode="cell">

        <Column field="id" header="ID"/>
        {/*UPDATE*/}
        <Column
          field="name"

          header="Name"
          style={{width: '15rem'}}
          onEditorInit={props => this.setState({newName: props.columnProps.rowData.name})}
          onEditorSubmit={props => {
            this.nameChanged(props.columnProps.rowData)
          }}
          editor={() => <InputText value={this.state.newName}
                                   style={{width: '14rem'}}
                                   onChange={el => this.setState({newName: el.target.value})}/>}/>
        {/*DELETE*/}
        <Column
          style={{textAlign: 'center', width: '3rem'}}
          body={(row) => <i className="pi pi-times-circle"
                            onClick={() => {
                              if(!row.id) return
                              axios.delete(BE + '/ribbons', {params: {id: row.id}}).then(res => {
                                this.load()
                              })
                            }}
                            style={{fontSize: '1.5rem', color: row.id ? 'crimson' : 'transparent'}}/>}/>
      </DataTable>
    </div>
  }

  state = {
    ribbons: [],
    newName: ''
  }

  nameChanged(row) {
    const row_id = row.id
    if (this.state.newName !== '') {
      axios.put(BE + '/ribbons', {
        id: row_id,
        name: this.state.newName
      }).then(res => {

        let clone = [...this.state.ribbons]

        let newRow = clone.find(el => el.id === row_id ? res.data.id : null )
        newRow.id = res.data.id
        newRow.name = res.data.name

        if(!row_id) {
          clone.push({id: null, name: ''})
        }
        this.setState({ribbons: clone})
      })
    }
  }

  load() {
    axios.get(BE + '/ribbons').then(res => {
      let {data} = res
      data.push({id: null, name: ''})
      this.setState({ribbons: res.data})
    })
  }

  componentDidMount() {
    this.load()
  }
}

export default Ribbons
