import React, {Component} from 'react'
import axios from "axios";

const BE = process.env.REACT_APP_MTG_BACK

class Email extends Component {

  render() {
    return <div style={{width: '400px'}}>
      <div dangerouslySetInnerHTML={{__html:this.state.mailHtml}}/>
    </div>
  }

  state = {
    mailHtml:''
  }
  load() {
    axios.get(BE + '/mail/get-example').then(res => {
      this.setState({mailHtml: res.data})
    })
  }

  componentDidMount() {
    this.load()
  }

}

export default Email
