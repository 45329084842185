import {Component} from 'react'
import axios from 'axios'
import {UserContext} from "../contexts/UserContext";

// Set backend path
axios.defaults.baseURL = process.env.REACT_APP_MTG_AUTH

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response
}, function (error) {
  // Do something with response error 403 (No access)
  if(error.response.status === 403){
    // Should end up here when we have an api token but its wrong
    // Got a 403 back from server
    window.location.href = '/'
  }
  return Promise.reject(error);
});

class Auth extends Component {
  static contextType = UserContext

  _role(name) {
    return JSON.parse(sessionStorage.getItem('userroles')).includes(name)
  }

  componentDidMount() {

    this.context.setUserName(sessionStorage.getItem('username'))
    this.context.setUserRoles(JSON.parse(sessionStorage.getItem('userroles')))

    /**
     * Just cosmetic stuff
     */
    switch (this.constructor.name){
      case 'PageUsers':
        if(!this._role('admin')){
          this.props.history.push('/404')
        }
        break;
      default:
        //
    }

  }

  constructor(props) {
    super(props);

    let api_token = sessionStorage.getItem('api_token');

    if(api_token){
      // Set oAuth header when we have one
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;

    } else {
      /**
       * @todo some redirect
       */
      console.log('@todo');
    }

  }
}

export default Auth;
