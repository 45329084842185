import React, { Component } from 'react';
import { FileService } from '../../services/file-service';
import {SplitButton} from 'primereact/splitbutton';
import { saveAs } from 'file-saver';
// const items = [
//   {
//     label: 'Update',
//     icon: 'pi pi-refresh',
//     command: (e) => {
//
//     }
//   },
//   {
//     label: 'Delete',
//     icon: 'pi pi-times',
//     command: (e) => {
//
//     }
//   },
//   {
//     label: 'React Website',
//     icon: 'pi pi-external-link',
//     command:(e) => {
//
//     }
//   },
//   {   label: 'Upload',
//     icon: 'pi pi-upload',
//     command:(e) => {
//
//     }
//   }
// ]

class DownloadWebshopProducts extends Component {

  render() {
    return (
      <div>
        <SplitButton
          style={{textAlign: 'left'}}
          label="Download"
          icon="pi pi-cloud-download"
          model={this.items}
          onClick={() => this.downloadFile('text')} />
        <label>{this.state.downloading ? 'Downloading in progress' : ''}</label>

      </div>
    )
  };

  items =  [
    {
      label: 'Als Tekst',
      // icon: 'pi pi-cloud-download',
      command: (e) => {
        this.downloadFile('text')
      }
    },
    {
      label: 'Als HTML',
      // icon: 'pi pi-cloud-download',
      command: (e) => {
        this.downloadFile('html')
      }
    },
    {
      label: 'Prijskaartjes',
      // icon: 'pi pi-cloud-download',
      command: (e) => {
        this.downloadPriceCards('nl_nl')
      }
    },
    {
      label: 'Prijskaartjes (EN)',
      // icon: 'pi pi-cloud-download',
      command: (e) => {
        this.downloadPriceCards('en_us')
      }
    },
  ]

  constructor(props) {
    super(props);
    this.fileService = new FileService();
    this.state={downloading:false};
  }


  downloadFile(locale) {

    this.setState({ downloading: true });

    let self = this;

    this.fileService.getProductsFromServer(this.props.webshop, locale).then((response) => {

      this.setState({ downloading: false});

      //extract file name from Content-Disposition header not possible
      saveAs(response.data, this.props.webshop.slug + '.xlsx');

    }).catch(function (error) {

      self.setState({ downloading: false });
      if (error.response) {
        console.log('Error', error.response.status);
      } else {
        console.log('Error', error.message);
      }
    });
  };

  downloadPriceCards(type) {

    this.setState({ downloading: true });

    let self = this;

    this.fileService.getProductPriceCardsFromServer(this.props.webshop, type).then((response) => {

      this.setState({ downloading: false});

      //extract file name from Content-Disposition header not possible
      saveAs(response.data, this.props.webshop.slug + "_pricecards.pdf");

    }).catch(function (error) {

      self.setState({ downloading: false });
      if (error.response) {
        console.log('Error', error.response.status);
      } else {
        console.log('Error', error.message);
      }
    });
  };


}
export default DownloadWebshopProducts