import React, { Component } from 'react';
import { FileService } from '../../services/file-service';
import {SplitButton} from 'primereact/splitbutton';
import { saveAs } from 'file-saver';

class DownloadSales extends Component {

  render() {
    return (
      <div style={{float:'right',marginTop: '-5px'}}>
        <SplitButton
          style={{textAlign: 'left'}}
          label="Download"
          icon="pi pi-cloud-download"
          model={this.items}
          onClick={() => this.downloadFile('extern')} />
        <label>{this.state.downloading ? 'Downloading in progress' : ''}</label>
      </div>
    )
  };

  items =  [
    {
      label: 'Intern gebruik',

      command: (e) => {
        this.downloadFile('intern')
      }
    },
    {
      label: 'Extern gebruik',

      command: (e) => {
        this.downloadFile('extern')
      }
    },
  ]

  constructor(props) {
    super(props);
    this.fileService = new FileService();
    this.state={downloading:false};
  }


  downloadFile(type) {

    this.setState({ downloading: true });

    let self = this;

    this.fileService.getSalesFromServer(this.props.webshop_id, type).then((response) => {

      this.setState({ downloading: false});

      //extract file name from Content-Disposition header not possible
      saveAs(response.data, this.props.slug + '.xlsx');

    }).catch(function (error) {

      self.setState({ downloading: false });
      if (error.response) {
        console.log('Error', error.response.status);
      } else {
        console.log('Error', error.message);
      }
    });
  };


}
export default DownloadSales