import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";


class DialogAreYouSure extends Component {

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: this.state.width}}
      position="top"
      modal={true}
      onHide={() => {
        this.setState({visible: false})
      }}>
      <div className="p-grid">
        <div className="p-col" style={{ whiteSpace: 'pre' }}>
          {this.state.text}
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col">
          <Button style={{width: '10em'}}
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={() => {
                    this.setState({visible: false})
                  }}/>

        </div>
        <div className="p-col">
          <Button style={{width: '10em', float:'right'}}
                  label="OK"
                  onClick={() => {
                    this.callback()
                    this.setState({visible: false})
                  }}/>

        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    text: '',
    header: '',
    position: 'top',
    width: '25em'

  }

  callback = null

  open(text, callback, header, position, width) {
    if(!position)
      position = 'top'
    if(!width)
      width = '50em'

    this.callback = callback
    this.setState({
      visible: true,
      text,
      header,
      width:width,
    })
  }
}

export default DialogAreYouSure
