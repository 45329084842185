import React from 'react'
import Menu from '../components/Menu'
import Webshops from '../components/data/Webshops'
import Auth from '../auth/Auth'

class PageWebshop extends Auth {

  render() {
    return (
      <React.Fragment>
        <Menu {...this.props}/>

        <Webshops {...this.props}/>
      </React.Fragment>
    )
  }
}

export default PageWebshop