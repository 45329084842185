import React from "react"
import {Dialog} from "primereact/dialog"

import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import axios from "axios";

import {Calendar} from "primereact/calendar";
import {Editor} from "primereact/editor";
import moment from "moment";



/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class DialogPubQuiz extends React.Component {

    footer = (
        <div className="p-field p-grid" style={{marginBottom: '2em'}}>
            <label className="p-col-4"></label>
            <div className="p-col">
                <Button label="Save" onClick={() => this.handleSubmit()} />
            </div>
        </div>
    );

    render() {
        return (
            <Dialog header="Add Pub Quiz" visible={this.state.visible}
                    style={{width: '80rem'}} modal={true}
                    onHide={() => this.close()}
                    footer={this.footer}>
                <form onSubmit={() => this.handleSubmit()} className="p-fluid">
                    <div className="p-grid">
                        <div className="p-col-4">Onderwerp</div>
                        <div className="p-col-8">
                            <InputText value={this.state.pubquiz.topic} required onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, topic: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Start</label>
                        <div className="p-col">
                            <Calendar showTime hourFormat="24" value={moment(this.state.pubquiz.start_datetime).toDate()} onChange={(e) => this.setState({pubquiz: {...this.state.pubquiz, start_datetime: moment(e.value).format("YYYY-MM-DD HH:mm:ss")}})}></Calendar>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Eind</label>
                        <div className="p-col">
                            <Calendar showTime hourFormat="24" value={moment(this.state.pubquiz.end_datetime).toDate()} onChange={(e) => this.setState({pubquiz: {...this.state.pubquiz, end_datetime: moment(e.value).format("YYYY-MM-DD HH:mm:ss")}})}></Calendar>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Vimeo ID</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.vimeo_id} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, vimeo_id: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Slido ID</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.slido_id} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, slido_id: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Zoom ID</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.zoom_id} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, zoom_id: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Zoom Wachtwoord</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.zoom_pw} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, zoom_pw: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Kahoot ID</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.kahoot_id} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, kahoot_id: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Youtube ID</label>
                        <div className="p-col">
                            <InputText value={this.state.pubquiz.youtube_id} onChange={(e) => {
                                this.setState({pubquiz: {...this.state.pubquiz, youtube_id: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Tekst voorafgaand</label>
                        <div className="p-col">
                            <Editor style={{height: '150px'}}
                                value={this.state.pubquiz.text_before}
                                onTextChange={(e) => this.setState({
                                    pubquiz: {
                                        ...this.state.pubquiz,
                                        text_before: e.htmlValue
                                    }
                                })}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Tekst tijdens</label>
                        <div className="p-col">
                            <Editor style={{height: '150px'}}
                                    value={this.state.pubquiz.text_during}
                                    onTextChange={(e) => this.setState({
                                        pubquiz: {
                                            ...this.state.pubquiz,
                                            text_during: e.htmlValue
                                        }
                                    })}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label className="p-col-4">Tekst nadat</label>
                        <div className="p-col">
                            <Editor style={{height: '150px'}}
                                    value={this.state.pubquiz.text_after}
                                    onTextChange={(e) => this.setState({
                                        pubquiz: {
                                            ...this.state.pubquiz,
                                            text_after: e.htmlValue
                                        }
                                    })}/>
                        </div>
                    </div>
                </form>
            </Dialog>
        )
    }

    state = {
        visible: false,
        pubquiz: {
            id: '',
            start_datetime: undefined,
            end_datetime: undefined,
            vimeo_id: '',
            slido_id: '',
            kahoot_id: '',
            text_before: '',
            text_after: ''
        },
    }

    open(id) {
        if(id) {
            axios.get(process.env.REACT_APP_MTG_BACK + '/get-pubquiz',
                {
                    params: {id: id}
                }).then(response => {
                    this.setState({pubquiz: response.data})
                });
        }
        this.setState({visible: true})
    }

    close() {
        this.setState({
            visible: false,
            pubquiz: {
                id: '',
                start_datetime: undefined,
                end_datetime: undefined,
                vimeo_id: '',
                slido_id: '',
                kahoot_id: '',
                text_before: '',
                text_after: ''
            },
        })
    }

    handleSubmit() {
        let pubquiz = this.state.pubquiz;
        pubquiz.webshoporder_id = this.props.match.params.webshop_id;
        axios.post(process.env.REACT_APP_MTG_BACK + '/save-pubquiz', pubquiz).then((res) => {
            if (res.data.msg === 'ok') {
                //     this.props.messages.show({severity: 'success', detail: 'Opgeslagen', closable: false});
                this.close();
                this.props.onClose('success','De PubQuiz is succesvol opgeslagen.')
            } else {
                this.props.messages.show({sticky: true, severity: 'error', detail: res.data.msg, closable: false});
            }
        }).catch((err) => {
            this.props.messages.show({sticky: true, severity: 'error', detail: err, closable: false});
        })
    }
}

export default DialogPubQuiz
