import React from 'react';
import Menu from '../components/Menu'
import Webshop from '../components/data/Webshop'
import Auth from '../auth/Auth'
import WebshopFieldset from '../components/WebshopFieldset'
import axios from "axios";


class PageWebshop extends Auth {

    state = {
        webshop:null
    }

    constructor(props) {
        super(props);
        this.loadWebshopProps();
    }

    loadWebshopProps() {
        axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshop',
            {params: {webshop_id: this.props.match.params.webshop_id}}
        ).then((response) => {
            this.setState({webshop:response.data})
        });
    }

    render() {
        if(!this.state.webshop) return null;
        return (
            <div>
                <Menu {...this.props}/>
                <WebshopFieldset type="webshop" webshop={this.state.webshop} >
                    <Webshop {...this.props} webshop={this.state.webshop}/>
                </WebshopFieldset>
            </div>
        )
    }


}

export default PageWebshop
