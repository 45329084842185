import React from "react"
import {Dialog} from "primereact/dialog"

import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import axios from "axios";
import {ProgressBar} from "primereact/progressbar";

const BACKEND = process.env.REACT_APP_MTG_BACK

/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class AddVouchersDialog extends React.Component {

  render() {
    return <Dialog header="Add voucher(s)" visible={this.state.visible}
                   style={{top: '100px', width: '40rem'}} modal={true}
                   onHide={() => this.close()}
                   onShow={() => {
                     // need other thread.. dunno why
                     this.numberRef.current.focus()
                   }}>

      <div className="p-grid">
        <div className="p-col-3">
          Count:
        </div>
        <div className="p-col-6">
          <InputText ref={this.numberRef}
                     style={{width: '100%', textAlign: 'right'}}
                     value={this.state.n}
                     onChange={
                       (e) => this.setState({n: e.target.value})
                     }
          />
        </div>

        <div className="p-col-3">
          <Button label="Add..." icon="pi pi-plus" onClick={
            () => this.addVouchersClicked(this.numberRef.current.value)
          } style={{marginRight: '.25em'}}/>
        </div>

      </div>
      <div className="p-grid">
        <div className="p-col-12">

          <ProgressBar value={this.state.progress} style={{visibility: this.state.progressVisible}}/>

        </div>
      </div>
    </Dialog>
  }

  state = {
    visible: false,
    progress: 0,
    progressVisible: 'hidden',
    n: ''
  }

  constructor(props) {
    super(props);
    this.numberRef = React.createRef()
  }

  open() {
    this.setState({visible: true})
  }

  close() {
    this.setState({
      visible: false,
      progressVisible: 'hidden',
      progress: 0,
      n: ''
    })
  }

  async addVouchersClicked(numberRequested) {
    // Show progress barr
    this.setState({
      progressVisible:'visible'
    })

    // Not all at once
    let parts = Math.floor(numberRequested / 100)
    let rest = numberRequested % 100;

    let countDone = 0;
    for (var i = 0; i < parts; i++) {
      countDone += 100;
      this.setState({progress: Math.round((countDone / numberRequested) * 100)})
      await this.runAddVouchers(100);
    }
    countDone += rest;
    this.setState({progress: Math.round((countDone / numberRequested) * 100)})

    await this.runAddVouchers(rest);

    this.close();
    this.props.onDialogAddedVouchers()

  }

  runAddVouchers(n) {
    return new Promise(resolve => {
      axios.post(BACKEND + '/add-vouchers', {n, webshop_id: this.props.match.params.webshop_id})
        .then((res) => {
          resolve();
        });
    });
  }
}

export default AddVouchersDialog
