import React, {Component} from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Messages} from 'primereact/messages';

import axios from 'axios';
import DialogAreYouSure from "../dialogs/DialogAreYouSure";
import DialogPubQuiz from "../dialogs/DialogPubQuiz";

const BACKEND = process.env.REACT_APP_MTG_BACK


export class PubQuizzes extends Component {

    render() {
        return (
            <div className="p-fluid" style={{marginTop: '1rem'}}>
                <Messages ref={(el) => this.messages = el}></Messages>

                <DialogPubQuiz
                    ref={this.DialogPubQuiz}
                    onClose={this.onDialogPubQuizClose}
                    webshop={this.state.webshop}
                    {...this.props}
                />

                {/* ========================== Dialogs ============================ */}
                <DialogAreYouSure ref={this.DialogAreYouSure}/>

                <div className="p-grid">
                    {/* ========================== Add ============================ */}
                    <div className="p-col-3">
                        <Button label="Add" icon="pi pi-plus"
                                onClick={() => this.DialogPubQuiz.current.open()}
                                style={{margin: '0 2px'}}
                        />
                    </div>

                    {/* ========================== Data ============================ */}
                    <div className="p-col-12">
                        <DataTable paginator={true}
                                   rows={20}
                                   value={this.state.rows}
                                   editable={true}
                                   style={{marginTop: '5px'}}
                                   resizableColumns
                                   columnResizeMode="fit"
                        >
                            {/*=======================================================*/}
                            <Column style={{ width:'50px' }}
                                    body={(row) => {
                                        return (
                                            <Button icon="pi pi-fw pi-pencil" style={{fontSize:'0.6rem'}} onClick={() => {
                                                this.DialogPubQuiz.current.open(row.id)
                                            }}/>)}}/>
                            {/*=======================================================*/}
                            <Column
                                field="topic"
                                header={"Onderwerp"}
                                sortable={true}
                            />{/*=======================================================*/}
                            <Column
                                field="start_datetime"
                                header={"Start"}
                                sortable={true}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="end_datetime"
                                header={"Einde"}
                                sortable={true}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="youtube_id"
                                header={"Youtube"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`https://youtu.be/${row.youtube_id}`} target={`_blank`}>
                                            {row.youtube_id}
                                        </a>)
                                }}
                                style={{minWidth:'150px'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="vimeo_id"
                                header={"Vimeo"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`https://player.vimeo.com/video/${row.vimeo_id}`} target={`_blank`}>
                                            {row.vimeo_id}
                                        </a>)
                                }}
                                style={{minWidth:'150px'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="slido_id"
                                header={"Slido"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`https://app.sli.do/event/${row.slido_id}`} target={`_blank`}>
                                            {row.slido_id}
                                        </a>)
                                }}
                                style={{minWidth:'150px'}}
                            />
                            {/*=======================================================*/}
                            <Column
                                field="kahoot_id"
                                header={"Kahoot"}
                                sortable={true}
                                body={(row) => {
                                    return (
                                        <a href={`https://kahoot.it/challenge/${row.kahoot_id}`} target={`_blank`}>
                                            {row.kahoot_id}
                                        </a>)
                                }}
                                style={{minWidth:'150px'}}
                            />
                            {/*=======================================================*/}
                            <Column style={{width: '50px'}}
                                    body={(row) => {
                                        return (
                                            <Button icon="pi pi-fw pi-trash" style={{fontSize: '0.6rem'}} onClick={this.deletePubQuiz.bind(this, row)}/>)
                                    }}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }

    state = {
        rows: [],
        dialogAddVisible: false
    }

    constructor(props) {
        super(props)
        this.DialogPubQuiz = React.createRef()
        this.DialogAreYouSure = React.createRef()
        this.messages = React.createRef()
        this.onDialogPubQuizClose =this.onDialogPubQuizClose.bind(this);
    }

    componentDidMount() {
        this.getPubQuizzes()
    }

    getPubQuizzes() {
        axios.get(BACKEND + '/get-pubquizzes', {params: {webshop_id: this.props.match.params.webshop_id}}).then((response) => {
            if (response.data.msg === 'ok') {
                this.setState({'rows': response.data.rows})
            } else {
                alert(response.data)
            }

        }).catch((error) => {
        });

    }

    deletePubQuiz(row, e) {
        this.DialogAreYouSure.current.open(
            "Je staat op het punt deze PubQuiz te verwijderen.\n" +
            "Als je zeker bent klik dan op OK?\n\n", () => {

                axios.post(BACKEND + '/delete-pubquiz', {id: row.id}).then(response => {
                    if (response.data.msg === 'ok') {
                        this.messages.show({
                            severity: 'success',
                            summary: 'Gelukt',
                            detail: (
                                <React.Fragment>PubQuiz {row.id} is succesvol verwijderd.<br /></React.Fragment>),
                            sticky: true
                        });
                        this.getPubQuizzes()
                    }

                }).catch(error => {
                    console.log(error);
                    alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
                });
            })

    }

    onDialogPubQuizClose(severity,text) {
        if(severity === 'success') {
            this.messages.show({
                severity: 'success',
                summary: 'Gelukt',
                detail: (
                    <React.Fragment>{text}</React.Fragment>),
                sticky: true
            });
        } else {
            this.messages.show({
                severity: 'error',
                summary: 'Fouten',
                detail: (
                    <React.Fragment>Er is iets fout gegaan!<br/>{text}<br/>Herlaadt de pagina en probeer nogmaals</React.Fragment>),
                sticky: true
            });
        }
        this.getPubQuizzes()
    }

}


export default PubQuizzes
