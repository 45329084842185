import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

import DialogAreYouSure from '../dialogs/DialogAreYouSure'
import DialogCloneWebshop from '../dialogs/DialogCloneWebshop'
import {Link} from 'react-router-dom'
import axios from "axios";
import {Button} from 'primereact/button';

const BACKEND = process.env.REACT_APP_MTG_BACK

let style = {
  sync: {
    backgroundColor: 'white',
    float: 'right',
    marginTop: '-3.5rem',
    padding: '0 0.8rem',
    color: '#c8c8c8'
  },
  col: {
    height: '2em', padding: '0.3em'
  },
  check: {
    fontSize: '1.2em', fontWeight: 'bold', color: 'green'
  },
  lock: {
    fontSize: '1.2em', fontWeight: 'bold', color: 'lightgray'
  }

}

export class Partners extends Component {

  render() {

    return (
      <div>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        <DialogCloneWebshop ref={this.DialogCloneWebshop}/>
        {/*=======================================================*/}
        <span style={style.sync}>
            <Button className={this.state.waitingForSync ? 'p-button-secondary' : 'p-button-success'}
                    icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}
                    style={{fontSize: '0.75rem'}}
                    onClick={() => this.syncPartnersWithOdoo()}/>
        </span>

        {/*=======================================================*/}
        <DataTable paginator={true}
                   rows={15}
                   value={this.state.rows} editable={true}
                   style={{marginTop: '5px'}}>

          <Column field="to_webshop" body={(row) => {
            return row.webshop_id
              ?
              <Link to={`/webshop/${row.webshop_id}`}>
                <i className="pi pi-fw pi-shopping-cart" title="Naar webshop..." style={{fontSize: '1.5em'}}></i>
              </Link>
              :
              (<div>
                <Button className="p-button-secondary" title="New webshop..." icon="pi pi-fw pi-plus-circle"
                        onClick={() => this.createWebshop(row)}
                        style={{fontSize: '1em', cursor: 'pointer', margin: '0 0.2rem'}}>
                </Button>
                <Button className="p-button-secondary" title="Copy webshop..." icon="pi pi-fw pi-clone"
                        onClick={() => this.cloneWebshop(row)}
                        style={{fontSize: '1em', cursor: 'pointer', margin: '0 0.2rem'}}>
                </Button>
              </div>)
          }} header="" style={{...style.col, width: '2.2rem', textAlign: 'center'}}/>

          <Column
            field="id"
            sortable={true}
            header={"ID"}
            filter={true}
            filterMatchMode={'contains'}
            filterPlaceholder={'Zoek op ID...'}
            style={{...style.col, width: '30px', textAlign: 'right'}}/>


          <Column
            field="display_name"
            sortable={true}
            header={"Partner Naam"}
            filter={true}
            filterMatchMode={'contains'}
            filterPlaceholder={'Zoek op naam...'}
            style={{...style.col, width: '360px'}}/>
          <Column
            field="active"
            sortable={true}
            header="Aktief"
            body={row => {
              switch (row.active) {
                case 1:
                  return <i className="pi pi-check" title="Webshop is aktief" style={style.check}></i>
                case 0:
                  return <i className="pi pi-lock" title="Webshop is closed" style={style.lock}></i>
                case -1:
                  return <i className="pi pi-plus-circle" title="Webshop does not exist" style={style.lock}></i>
                default:
                  return ''
              }
            }}
            className="t-center"
            style={{width: '3em'}}

          />
        </DataTable>
      </div>
    );
  }

  state = {
    rows: [],
    sidebarVisible: false,
    waitingForSync: false
  }

  constructor(props) {
    super(props);
    this.DialogAreYouSure = React.createRef()
    this.DialogCloneWebshop = React.createRef()
    this.createWebshop = this.createWebshop.bind(this)
  }

  componentDidMount() {
    this.getRows()
  }

  getRows() {
    axios.get(BACKEND + '/get-partners').then(response => {
      this.setState({rows: response.data})
    });
  }

  syncPartnersWithOdoo() {
    this.setState({waitingForSync: true})
    axios.get(BACKEND + '/sync-partners-with-odoo').then(res => {
      this.getRows()
      this.setState({waitingForSync: false})
    });
  }

  cloneWebshop(row) {
    this.DialogCloneWebshop.current.open(row.display_name, (webshop_id) => {
      axios.post(BACKEND + '/clone-webshop', {partner_id: row.id, webshop_id}).then(res => {

        if (res.data.msg === 'ok') {
          // Set the webshop_id of current row
          let newRows = this.state.rows
          newRows.find(i => i.id === row.id).webshop_id = res.data.id;

          // And update the state
          this.setState({
            rows: newRows
          })
        }
      }).catch(error => {
        console.log(error);
      });

      console.log(webshop_id);
    })
  }

  createWebshop(row) {

    this.DialogAreYouSure.current.open(
      "Je staat op het punt een webshop aan te maken.\n\n" +
      "Weet je dit zeker?\n\n", () => {

        axios.post(BACKEND + '/new-webshop', row).then(res => {

          if (res.data.msg === 'ok') {
            // Set the webshop_id of current row
            let newRows = this.state.rows
            newRows.find(i => i.id === row.id).webshop_id = res.data.id;

            // And update the state
            this.setState({
              rows: newRows
            })
          }
        }).catch(error => {
          console.log(error);
        });

      }, row.display_name)


  }


}

export default Partners
