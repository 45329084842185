import React from 'react'
import Menu from '../components/Menu'
import {Fieldset} from 'primereact/fieldset'
import Products from '../components/data/Products'
import Auth from '../auth/Auth'

class PageProducts extends Auth {

  render() {
    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset legend="Producten" style={{fontSize: '1.3rem'}}>
          <Products/>
        </Fieldset>
      </div>
    )
  }
}

export default PageProducts