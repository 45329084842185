import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";


class DialogEditField extends Component {

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: '20em'}}
      position="center"
      modal={true}
      onShow={() => {
        this.InputText.current.focus()
      }}
      onHide={() => {
        this.setState({visible: false})
      }}>
      <div className="p-grid">
        <div className="p-col-12" style={{whiteSpace: 'pre'}}>
          <InputText
            ref={this.InputText}
            style={{width: '100%', textAlign: this.state.textAlign}}
            value={this.state.text}
            onChange={(e) => {
              this.setState({text: e.target.value})
            }}
          />
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-6">
          <Button style={{width: '100%'}}
                  label="OK"
                  onClick={() => {
                    this.callback(this.state.text)
                    this.setState({visible: false})
                  }}/>
        </div>
        <div className="p-col-6">
          <Button style={{width: '100%'}} label="Cancel" className="p-button-secondary"
                  onClick={() => {
                    this.setState({visible: false})
                  }}/>

        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    textAlign: 'left',
    text: '',
    header: ''
  }

  callback = null

  constructor(props) {
    super(props);
    this.InputText = React.createRef()
  }


  open(text, callback, header) {


    this.callback = callback
    this.setState({
      visible: true,
      textAlign: isNaN(text) ? 'left' : 'right',
      text,
      header
    })
  }
}

export default DialogEditField;
