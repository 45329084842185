import React, {Component} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {ToggleButton} from 'primereact/togglebutton'
import DialogProductProperties from '../../dialogs/DialogProductProperties'
import money from '../../../services/money'
import {lookup} from '../../../enum/btw'
import DownloadSales from '../../download/DownloadSales'
import axios from "axios";
import {Link} from "react-router-dom";
import moment from "moment";
import DownloadOrders from '../../download/DownloadOrders'

let legenda = {
  padding: '2px 4px',
  margin: '0px 8px',
  border: 'solid 1px #ccc',
  borderRadius: '0.3rem'
}
let style = {
  check: {
    fontSize: '1.2em', fontWeight: 'bold', color: 'green'
  },
  lock: {
    fontSize: '1.2em', fontWeight: 'bold', color: 'lightgray'
  },
}

export class Sales extends Component {

  render() {

    return (
      <div>

        <DialogProductProperties ref={this.DialogProductProperties}/>

        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col-12"/>
        </div>

       

        {!this.state.webshop_id && <div className="p-grid">
          <div className="p-col-12">
            <ToggleButton offLabel="Actief"
                          onLabel="Actief"
                          checked={this.state.legenda[1]}
                          onChange={(e) => {
                            this.legendaClicked(1, e)
                          }}
                          style={{marginLeft: '8px'}}/>
            <ToggleButton offLabel="Inactief"
                          onLabel="Inactief"
                          checked={this.state.legenda[0]}
                          onChange={(e) => {
                            this.legendaClicked(0, e)
                          }}
                          style={{marginLeft: '8px'}}/>

            <DownloadOrders/>

          </div>
        </div>}

        <div className="p-grid">
          {/* ================ */}
          {/* All the webshops */}
          {/* ================ */}
          {!this.state.webshop_id &&
          <div className="p-col-12">
            <DataTable paginator={true}
                       ref={this.datatable_webshops_ref}
                       rows={250}
                       removableSort={true}
                       value={this.state.webshops}
                       style={{marginTop: '5px'}}>
              <Column field="wso_id" header="ID" className="t-right t-w-4"/>
              <Column field="wso_display_name"
                      sortable={true}
                      filter={true}
                      filterMatchMode={'contains'}
                      header="Webshop"
                      body={(row) => {
                        return <Link to={`/reports/sales/${row.wso_id}`}>
                          {row.wso_display_name}
                        </Link>
                      }}/>
              <Column field="full" className="t-right t-w-6" sortable={true} header="Free"/>
              <Column field="used" className="t-right t-w-6" sortable={true} header="Used"/>
              <Column field="end_date"
                      className="t-center t-w-8"
                      sortable={true}
                      header="End Date"
                      body={(row) =>
                        moment(row.end_date).format('DD MMM YYYY')}/>
              <Column field="euro_amount"
                      className="t-right t-w-8"
                      sortable={true}
                      body={(row) => money.toEuro(row.euro_amount, true)}
                      header="Euro's"/>
              <Column
                field="active"
                sortable={true}
                header="Aktief"
                body={row => {
                  switch (row.active) {
                    case 1:
                      return <i className="pi pi-check" title="Webshop is aktief" style={style.check}></i>
                    case 0:
                      return <i className="pi pi-lock" title="Webshop is closed" style={style.lock}></i>
                    default:
                      return '';
                  }
                }}
                className="t-center"
                style={{width: '7em'}}
              />
            </DataTable>
          </div>
          }

          {/* ================ */}
          {/* Te actual report */}
          {/* ================ */}
          {this.state.webshop_id &&
          <div className="p-col-12">
            <Link style={{fontWeight: 'bold'}} to="/reports/sales">{'<<< ' + this.state.wso.wso_display_name}</Link>
            <span className="t-strong" style={{marginLeft: '0.5rem'}}>Budget Totaal:</span>
            <span style={legenda}>{money.toEuro(this.state.wso.euro_amount, true)}</span>
            <span className="t-strong" title="Budget in/ excl BTW">BTW:</span>
            <span style={legenda}>{lookup(this.state.wso.fiscal_position_id)}</span>
            <span className="t-strong">Eco's:</span>
            <span style={legenda}>{this.state.wso.voucher_amount}</span>
            <span className="t-strong" title="Niet verzilverd">Free:</span>
            <span style={legenda}>{this.state.wso.full}</span>
            <span className="t-strong" title="Verzilverd">Used:</span>
            <span style={legenda}>{this.state.wso.used}</span>
            <span className="t-strong">End Date</span>
            <span style={legenda}>{moment(this.state.wso.end_date).format('DD MMM YYYY')}</span>
            <DownloadSales webshop_id={this.state.wso.wso_id} slug={this.state.wso.wso_slug}/>
          </div>
          }
          {this.state.webshop_id &&
          <div className="p-col-12">
            <DataTable paginator={true}
                       rows={20}
                       value={this.state.webshop_detail}>
              <Column field="klant" header="Klant" style={{width: '10rem'}}/>
              <Column field="artiekel"
                      body={(row) => {
                        return <span style={{cursor: 'pointer'}} onClick={() => {
                          this.DialogProductProperties.current.open(
                            row.artiekelnummer,
                            row.artiekel
                          )
                        }}>{row.artiekel}</span>
                      }}
                      header="Artikel"
                      style={{width: '23rem'}}/>
              <Column field="aantal"
                      className="t-center"
                      header={<span title="Aantal">#</span>}
                      style={{width: '2rem'}}/>
              <Column field="categorie"
                      header={<span title="Categorie">Categorie</span>}
                      style={{width: '10rem'}}/>
              <Column field="btw"
                      body={(row) => row.btw + '%'}
                      className="t-right"
                      header={<span title="BTW">BTW</span>}
                      style={{width: '3rem'}}/>
              <Column field="waarde_1_eco_excl_btw"
                      body={(row) => money.toEuro(row.waarde_1_eco_excl_btw, true)}
                      className="t-right"
                      header={<span title="Prijs Eco">Prijs Eco</span>}
                      style={{width: '5rem'}}/>
              <Column field="price_in_eco"
                      className="t-right"
                      header={<span title="Prijs Eco">Eco's</span>}
                      style={{width: '3rem'}}/>
              <Column field="verkoopprijs"
                      body={(row) => money.toEuro(row.verkoopprijs, true)}
                      className="t-right"
                      header={<span title="Verkoopprijs">Prijs</span>}
                      style={{width: '5rem'}}/>
              <Column field="kostprijs"
                      body={(row) => money.toEuro(row.kostprijs, true)}
                      className="t-right"
                      header={<span title="Kostprijs">Kost</span>}
                      style={{width: '4rem'}}/>
              <Column field="marge"
                      body={(row) => money.toEuro(row.marge, true)}
                      className="t-right"
                      header="Marge"
                      style={{width: '4rem'}}/>
              <Column field="marge_perc"
                      body={(row) => {
                        return money.toEuro(row.marge_perc) + '%'
                      }}
                      className="t-right"
                      header="Marge%"
                      style={{width: '5rem'}}/>
              <Column field="marktconform"
                      body={(row) => {
                        return row.marktconform === '-' ? '-' : money.toEuro(row.marktconform, true)
                      }}
                      className="t-right"
                      header={<span title="Marktconform">Markt</span>}
                      style={{width: '4rem'}}/>
            </DataTable>
          </div>
          }
        </div>
      </div>
    );
  }

  state = {
    webshop_id: null,
    webshops: [],
    webshop_detail: [],
    wso: null,
    legenda: [false, false],
    filter: []
  }

  constructor(props) {
    super(props);
    this.DialogProductProperties = React.createRef()
    this.datatable_webshops_ref = React.createRef()
  }

  /**
   *
   * @param legenda
   * @param e
   *
   * Some shitty code
   */
  legendaClicked(key, e) {

    let legenda = this.state.legenda
    legenda[key] = e.value

    // make filter array
    let filter = [];
    legenda.forEach((val, i) => {
      if (val) {
        filter.push(i);
      }
    })

    this.datatable_webshops_ref.current.filter(filter, 'active', 'in')
    this.setState({legenda, filter})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Horrible hack
    if(this.datatable_webshops_ref.current && !this.datatable_webshops_ref.current.state.filters){
      this.datatable_webshops_ref.current.filter(this.state.filter, 'active', 'in')
    }

    if (this.props.location !== prevProps.location) {
      this.props.match.params.id
        ? this.getWebshopDetail(this.props.match.params.id)
        : this.setState({webshop_id: null})
    }
  }

  componentDidMount() {
    this.getWebshops()
  }

  getWebshops() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/report-sales-all-webshops').then(response => {
      this.setState({
        webshop_id: null,
        webshops: response.data,
      }, () => {
        setTimeout(() => {
          let input = this.datatable_webshops_ref.current.container.getElementsByTagName('input')[0]
          input.focus()
        }, 0)
      })
    })
  }

  getWebshopDetail(webshop_id) {
    axios.get(process.env.REACT_APP_MTG_BACK + '/report-sales?id=' + webshop_id + '&type=sales').then(response => {
      this.setState({
        webshop_id: webshop_id,
        webshop_detail: response.data.result,
        wso: response.data.wso
      })
    })
  }

}

export default Sales
