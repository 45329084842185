import React from 'react'

import {Button} from "primereact/button";
import {Link} from "react-router-dom";

const WEBSHOP = process.env.REACT_APP_MTG_WEBSHOP

export default function WebshopFieldset(props) {

  var  {webshop} = props;


  let style = {
    fieldset: {
      border: 'solid 1px gainsboro',
      marginTop: '1.5rem',
      padding: '1rem'
    },
    menu: {
      marginTop: '-2.1rem',
      marginBottom: '0.5rem',
      fontSize: '1.2rem'
    },
    button: {
      marginLeft: '0.5rem',
    },
    displayName: {
      display:'block',
      position: 'absolute',
      left: '33.5rem',
      top: '5.2rem',
      backgroundColor: '#ffffff',
      padding: '0 0.5rem',
      zIndex:0,
      color: '#333333'
    }


  }

  switch (props.type) {
    case 'webshop':
      var postfix = ` (${webshop.voucher_amount} eco's)`
      break
    case 'vouchers':
      postfix = ' (Vouchers)'
      break
    case 'webshop_properties':
      postfix = ' (Eigenschappen)'
      break
    case 'virtual_markets':
      postfix = ' (Virtuele kerstmarkt)'
      break
    case 'tiny_cms':
      postfix = ' (Tiny-CMS)'
      break
    default:
      postfix =''
  }

  let displayName = `${webshop.display_name} ${postfix}`

  let api_token = sessionStorage.getItem('api_token');

  /**
   * HTML
   */
  let html = (
    <div style={style.fieldset}>
      <div style={style.menu}>
          <span style={{backgroundColor: '#ffffff',zIndex:1}}>
            <Link to={`/webshop/${webshop.id}`}>
              <Button className={props.type === 'webshop' ? 'p-button-warning' : 'p-button-secondary'}
                      icon="pi pi-sitemap"
                      style={style.button}/>
            </Link>
            <Link to={`/vouchers/${webshop.id}`}>
              <Button className={props.type === 'vouchers' ? 'p-button-warning' : 'p-button-secondary'}
                      icon="pi pi-key"
                      style={style.button}/>
            </Link>
            <Link to={`/webshop-properties/${webshop.id}`}>
              <Button className={props.type === 'webshop_properties' ? 'p-button-warning' : 'p-button-secondary'}
                      icon="pi pi-pencil"
                      style={style.button}/>
            </Link>
            <Link to={`/virtual-markets/${webshop.id}`}>
              <Button className={props.type === 'virtual_markets' ? 'p-button-warning' : 'p-button-secondary'}
                      icon="pi pi-video"
                      style={style.button}/>
            </Link>
            <Link to={`/tiny-cms/${webshop.id}`}>
              <Button className={props.type === 'tiny_cms' ? 'p-button-warning' : 'p-button-secondary'}
                      icon="pi pi-palette"
                      style={style.button}/>
            </Link>

            <a href={`${WEBSHOP}/mtg-login/#/${webshop.id}/${api_token}`}
               target="_blank" rel="noopener noreferrer">
              <Button className="p-button-secondary"
                      icon="pi pi-external-link"
                      style={{...style.button,marginRight:'0.5rem'}}/>
            </a>

          </span>
        <span style={style.displayName}>{displayName}</span>
      </div>
      <div style={{height:'0.5rem'}}/>
      {props.children}
    </div>
  );

  return html;

}







