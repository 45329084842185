import React, {Component} from 'react'
import {Dialog} from 'primereact/dialog'
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner'
import axios from "axios";
import {Button} from "primereact/button";

import {InputTextarea} from "primereact/inputtextarea";

const BE = process.env.REACT_APP_MTG_BACK

class DialogLeverancierDescriptions extends Component {

  getLoading() {
    return <div style={{width: '880px', height: '520px'}}>
      <ProgressSpinner style={{height: '100px', left: '390px', top: '210px'}}/>
      <span style={{display: 'block', position: 'absolute', left: '424px', top: '262px'}}>
        Working...
      </span>
    </div>
  }

  getBody() {
    return <div style={{width: '880px', height: '520px'}}>
      <div className="p-grid">
        <div className="p-col-6">
          <InputTextarea rows={20} style={{width: '100%'}} value={this.state.properties.nl_nl} readOnly={true}/>
        </div>
        <div className="p-col-6">
          <InputTextarea rows={20} style={{width: '100%'}} value={this.state.properties.en_us} readOnly={true}/>
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-6">
          <label htmlFor={'nl_nl'} className="p-checkbox-label">Use:{' '}</label>
          <Checkbox inputId={'nl_nl'}
                    checked={this.state.selectedLang.nl_nl}
                    onChange={() => {
                      const clone = {...this.state.selectedLang}
                      clone.nl_nl = !clone.nl_nl
                      this.setState({
                          selectedLang: clone
                        }
                      )
                    }}/>

        </div>
        <div className="p-col-3">
          <label htmlFor={'en_us'} className="p-checkbox-label">Use:{' '}</label>
          <Checkbox inputId={'en_us'}
                    checked={this.state.selectedLang.en_us}
                    onChange={() => {
                      const clone = {...this.state.selectedLang}
                      clone.en_us = !clone.en_us
                      this.setState({
                          selectedLang: clone
                        }
                      )
                    }}/>
        </div>
        <div className="p-col-3">
          <Checkbox style={{marginLeft: '0.5  rem', float: 'right'}}
                    inputId="selall"
                    checked={this.state.selectAll}
                    onChange={(e) => {
                      const data = this.state.selectAll ? {nl_nl: false, en_us: false} : {nl_nl: true, en_us: true}
                      this.setState({
                        selectedLang: data,
                        selectAll: e.checked
                      })
                    }}/>
          <label style={{marginLeft: '1rem', float: 'right'}} htmlFor="selall" className="p-checkbox-label">Select
            all:&nbsp;</label>
        </div>

      </div>

      <div className="p-grid" style={{marginTop: '1rem'}}>
        <div className="p-col-12">
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-3">
          <Button label="Cancel"
                  className="p-button-warning"
                  style={{width: '100%'}}
                  onClick={() => this.close()}/>
        </div>
        <div className="p-col-6"/>
        <div className="p-col-3">
          <Button label="OK"
                  style={{width: '100%', float: 'right'}}
                  onClick={() => this.process()}/>
        </div>

      </div>
    </div>
  }

  getNothingToDo() {
    return <div>
      Geen leveranciers beschrijvingen gevonden voor dit product.
    </div>
  }


  render() {
    return (
      <Dialog
        header={`Leveranciers Omschrijvingen`}
        position={'top'}
        visible={this.state.visible}

        modal={true}
        onHide={() => {
          this.close()
        }}>

        {this.state.loading
          ? this.getLoading()
          : this.getBody()
        }

      </Dialog>

    )
  }

  state = {
    visible: false,
    product_id: null,
    loading: true,
    properties: [],
    selectAll: false,
    selectedLang: {nl_nl: false, en_us: false},
  }

  callback = null

  succes() {
    this.callback()
    this.close()
  }

  open(product_id, callback) {
    this.callback = callback

    axios.get(process.env.REACT_APP_MTG_BACK + '/get-supplier-descriptions?product_id=' + product_id).then(res => {

      this.setState({
        properties: res.data,
        loading: false
      })
    })

    this.setState({
      visible: true,
      product_id
    })

  }

  process() {

    const {properties, selectedLang, product_id} = this.state
    this.setState({loading: true})
    axios.post(BE + '/process-supplier-descriptions',
      {
        product_id,
        properties,
        selectedLang
      }).then(res => {

      this.succes()
    })


  }

  close() {
    this.setState({
      visible: false,
      product_id: null,
      properties: [],
      loading: true,
      selectAll: false,
      selectedLang: {nl_nl: false, en_us: false},
    })
  }
}

export default DialogLeverancierDescriptions
