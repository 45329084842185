import service from './rest-service';

export class FileService {
    getVoucersFromServer(webshop) {
        return service.getRestClient().get('/download-vouchers?webshop_id=' + webshop.id, {responseType: "blob"});
    }
    getInvitationsFromServer(webshop) {
        return service.getRestClient().get('/download-invitations?webshop_id=' + webshop.id, {responseType: "blob"});
    }
    getRemindersFromServer(webshop) {
        return service.getRestClient().get('/download-reminders?webshop_id=' + webshop.id, {responseType: "blob"});
    }

    getProductsFromServer(webshop, type) {
        return service.getRestClient().get('/download-webshop-products'+
          '?webshop_id=' + webshop.id +
          '&type=' + type,
          {responseType: 'blob'});
    }

    getProductPriceCardsFromServer(webshop,locale) {
        return service.getRestClient().get('/download-webshop-product-price-cards'+
            '?webshop_id=' + webshop.id +
            '&locale='+ locale,
            {responseType: 'blob'});
    }

    getPrognoseFromServer(product_id, by_orders) {
        return service.getRestClient().get('/download-prognose'+
          '?product_id=' + product_id + '&by_orders='+by_orders,
          {responseType: 'blob'});
    }

    getOrdersFromServer() {
        return service.getRestClient().get('/download-orders',
          {responseType: 'blob'});
    }

    getStocksFromServer(noStock = false) {
        if (noStock) {
            return service.getRestClient().get('/download-stocks?id='+noStock,
              {responseType: 'blob'});
        } else {
            return service.getRestClient().get('/download-stocks',
              {responseType: 'blob'});
        }
    }

    getSalesFromServer(webshop_id, type) {
        return service.getRestClient().get('/download-sales-report'+
          '?webshop_id=' + webshop_id +
          '&type=' + type,
          {responseType: 'blob'});
    }
    getProductSalesFromServer(product_id, type,start,end) {
        return service.getRestClient().get('/download-product-sales-report'+
          '?product_id=' + product_id +
          '&type=' + type +
          '&date_start=' + start +
          '&date_end=' + end,
          {responseType: 'blob'});

    }

    getProductSalesAllFromServer(start, end) {
        return service.getRestClient().get('/download-product-sales-all'+
          '?date_start=' + start +
          '&date_end=' + end,
          {responseType: 'blob'});

    }
    getMessagesFromServer(webshop) {
        return service.getRestClient().get('/download-messages'+
            '?webshop_id=' + webshop.id,
            {responseType: 'blob'});
    }
    getMusicPollFromServer(webshop) {
        return service.getRestClient().get('/download-music-poll'+
            '?webshop_id=' + webshop.id,
            {responseType: 'blob'});
    }

    getWebshopsFromServer() {
        return service.getRestClient().get('/download-webshops',
          {responseType: 'blob'});
    }
}
