import React from 'react'
import Menu from '../components/Menu'
import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import {InputText} from "primereact/inputtext";

const BE = process.env.REACT_APP_MTG_BACK

class PageUsers extends Auth {

  state = {
    data: []
  }

  load(data) {
    if(!data){
      axios.get(BE + '/user/all').then(res => {
        let {data} = res
        data.push({id: null, name: ''})
        this.setState({data: res.data})
      })
    } else {
      data.push({id: null, name: ''})
      this.setState({data})
    }
  }

  constructor(props) {
    super(props);
    this.onRowEditCancel = this.onRowEditCancel.bind(this)
    this.onEditorValueChange = this.onEditorValueChange.bind(this)
    this.onRowEditInit = this.onRowEditInit.bind(this)
    this.codeEditor = this.codeEditor.bind(this)
    this.onRowEditSave=this.onRowEditSave.bind(this)
    this.inputTextEditor = this.inputTextEditor.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.load()
  }

  /**
   *  Inline Editor
   */
  originalRows = {};

  onEditorValueChange(productKey, props, value) {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    this.setState({[`${productKey}`]: updatedProducts});
  }

  onRowEditSave(e) {
    const { index, data } = e;
    axios.post(BE + '/user/save', data).then(res => {

      this.load(res.data);
    })

    console.log(index);
  }

  inputTextEditor(productKey, props, field) {
    return <InputText type="text"
                      style={{width:'100%'}}
                      value={props.rowData[productKey] || ''}
                      onChange={(e) => this.onEditorValueChange(productKey, props, e.target.value)}/>;
  }

  codeEditor(productKey, props) {
    return this.inputTextEditor(productKey, props, 'code');
  }

  onRowEditInit(e) {
    this.originalRows[e.index] = {...this.state.data[e.index]};
  }

  onRowEditCancel(e) {
    let products = [...this.state.data];
    products[e.index] = this.originalRows[e.index];
    delete this.originalRows[e.index];
    this.setState({data: products});
  }

  render() {

    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Users">
          <p>Voor een nieuw Google 2Fa token maak je het 'Google 2Fa' leeg. De gebruiker krijgt dan een nieuwe QR code te zien.</p>
          <p>Voor een nieuw wachtwoord maak of laat je het 'Wachtwoord' veld leeg. Het nieuwe wachtwoord wordt hier dan eenmalig getoond.</p>
          <DataTable value={this.state.data}
                     editMode="row"
                     dataKey="id"
                     rows={15}
                     paginator={true}
                     onRowEditInit={this.onRowEditInit}
                     onRowEditCancel={this.onRowEditCancel}
                     onRowEditSave={this.onRowEditSave}
                     style={{marginTop: '5px'}}>
            {/*=======================================================*/}
            <Column field="id" header="ID" style={{width:'5%'}}/>
            <Column field="name" header="Naam" style={{width:'15%'}} editor={(props) => this.codeEditor('name', props)}/>
            <Column field="email" header="Email" style={{width:'15%'}} editor={(props) => this.codeEditor('email', props)}/>
            <Column field="password" header="Wachtwoord" style={{width:'15%'}} editor={(props) => this.codeEditor('password', props)}/>
            <Column field="google2fa_secret" header="Google 2Fa" style={{width:'15%'}} editor={(props) => this.codeEditor('google2fa_secret', props)}/>
            <Column field="roles" header="Rollen" editor={(props) => this.codeEditor('roles', props)}/>
            <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
          </DataTable>
        </Fieldset>
      </div>
    )
  }


}

export default PageUsers
