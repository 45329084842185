import React from 'react'
import Menu from '../components/Menu'

import Auth from '../auth/Auth'
import {Fieldset} from "primereact/fieldset";

import {TabPanel, TabView} from "primereact/tabview";
import Ribbons from "../components/data/settings/Ribbons";
import Email from "../components/data/settings/Email";
class PageSettings extends Auth {
    state = {
        tabIndex: 0
    }

    render() {
        return (
            <div>
                <Menu {...this.props}/>
                <Fieldset style={{fontSize: '1.2rem', color: '#4444444'}} legend="Global settings">
                    <TabView activeIndex={this.state.tabIndex} onTabChange={(e) => this.onTabChange(e)}>
                        <TabPanel header="Ribbons">
                            <Ribbons/>
                        </TabPanel>
                        <TabPanel header="Email">
                            <Email/>
                        </TabPanel>

                    </TabView>
                </Fieldset>
            </div>
        )
    }
    onTabChange(e) {
        this.setState({tabIndex:e.index})
    }


}

export default PageSettings
