import React, {Component} from 'react'

import axios from 'axios'
import {Messages} from 'primereact/messages';
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";

import {SelectButton} from "primereact/selectbutton";
import DialogAreYouSure from "../dialogs/DialogAreYouSure";

let styleTextara = {
  width: '100%',
  lineHeight: '1.42',
  padding: '12px 15px',
  fontFamily: 'Helvetica, Arial, sans-serif',
  fontSize: '13px'
}
const BE = process.env.REACT_APP_MTG_BACK

let languages = [{label: 'NL', 'value': 'NL'}, {label: 'EN', 'value': 'EN'}]

class ProductSpecs extends Component {

  render() {
    if (!this.state.product) return null;
    return (
      <div style={{fontSize: '1rem'}}>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid">
          <div className="p-col"/>
        </div>

        <div className="p-grid">
          {/*==========================   LEFT BUTTS   =================================*/}
          <div className="p-col-3">
            <div>Product specs</div>
            <div>
              <SelectButton
                style={{marginTop: '0.5rem'}}
                value={this.state.webshop_lang}
                options={languages}
                onChange={(e) => this.setState({webshop_lang: e.value ? e.value : 'NL'})}
              />
            </div>
            <div>
              <Button label="Vertaal"
                      disabled={this.state.translate_button_disabled}
                      icon="pi pi-google"
                      className="p-button-success"
                      style={{margin: '0.5rem 0', width: '8rem'}}
                      onClick={() => this.translateClicked()}/>

            </div>
            <p>
              Plak of typ jouw product-specificaties in het tekstveld
            </p>
            <p>
              Een dubbele punt `:` scheidt de naam en de specificatie
            </p>
            <p>
              Wil je een `:` in de naam of specificatie hebben gebruik dan twéé dubbele punten `::`
            </p>
          </div>

          {/*==========================   RAW   =================================*/}
          {this.state.webshop_lang === 'NL' &&
          <div className="p-col-9">
            <InputTextarea style={styleTextara}
                           rows={15}
                           onChange={e => {
                             this.specChanged(e.target.value, 'NL')
                           }}
                           value={this.state.specs_raw}/>
          </div>}

          {this.state.webshop_lang === 'EN' &&
          <div className="p-col-9">
            <InputTextarea style={styleTextara}
                           rows={15}
                           onChange={e => {
                             this.specChanged(e.target.value, 'EN')
                           }}
                           value={this.state.specs_raw_en_US}/>
          </div>}
        </div>
        {/*=============================   RESULT   =================================*/}

        {this.state.webshop_lang === 'NL' &&
        <div className="p-grid" style={{marginBottom: '2em'}}>
          <div className="p-col-3">Resultaat</div>
          <div className="p-col-9">
            <div style={{border: 'solid 1px #ccc', borderRadius: '3px', padding: '0.5rem'}}>
              {this.state.specs.map(el => {
                return <div style={{display: 'flex'}}>
                  <div style={{fontWeight: 'bold', width: '35%'}}>{el.name}</div>
                  <div>{el.spec}</div>
                </div>
              })}
            </div>
          </div>
        </div>}

        {this.state.webshop_lang === 'EN' &&
        <div className="p-grid" style={{marginBottom: '2em'}}>
          <div className="p-col-3">Resultaat</div>
          <div className="p-col-9">
            <div style={{border: 'solid 1px #ccc', borderRadius: '3px', padding: '0.5rem'}}>
              {this.state.specs_en_US.map(el => {
                return <div style={{display: 'flex'}}>
                  <div style={{fontWeight: 'bold', width: '35%'}}>{el.name}</div>
                  <div>{el.spec}</div>
                </div>
              })}
            </div>
          </div>
        </div>}

        {/*============================== BUTT =================================*/}
        <div className="p-grid" style={{marginBottom: '2em'}}>
          <div className="p-col-3"></div>
          <div className="p-col-9">
            <Button label="Save" onClick={() => {
              this.save()
            }}/>
          </div>
        </div>

        {/*============================== MESSAGE =================================*/}
        <div className="p-grid" style={{marginBottom: '2em'}}>
          <div className="p-col-12">
            <Messages ref={(el) => this.savemessage = el}></Messages>
          </div>
        </div>
      </div>
    )
  }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  state = {
    product: null,
    webshop_lang: 'NL',
    specs: [],
    specs_en_US: [],
    specs_raw: '',
    specs_raw_en_US: '',
  }

  async componentDidMount() {

    let product = await this.getData()

    const specs = JSON.parse(product.specs || '[]')
    const specs_en_US = JSON.parse(product.specs_en_US || '[]')

    let specs_raw = '', specs_raw_en_US = ''
    specs.forEach(s => {
      specs_raw += s.name.replace(':','::') + ': ' + s.spec.replace(':','::') + '\n'
    })
    specs_en_US.forEach(s => {
      specs_raw_en_US += s.name.replace(':','::') + ': ' + s.spec.replace(':','::') + '\n'
    })
    this.setState({
      product,
      specs,
      specs_en_US,
      specs_raw,
      specs_raw_en_US,
    })
  }

  constructor(props) {
    super(props);
    this.DialogAreYouSure = React.createRef()
  }


  translateClicked() {

    // Check if EN prod has text already
    let {specs_raw_en_US} = this.state;

    if (specs_raw_en_US) {
      this.DialogAreYouSure.current.open(
        `De specs ${this.state.product.display_name} zijn al vertaald.\n\n` +
        `Door op ja te klikken overscrijf je de reeds bestaande vertaling\n\n` +
        `Weet je dit zeker?\n\n`
        , () => {
          this.doTranslate()
        },
        'Google Translate Product')
    } else {
      this.doTranslate()
    }
  }

  doTranslate() {
    axios.post(BE + '/translate-text',
      {text: this.state.specs_raw}).then(res => {
      this.setState({
          specs_raw_en_US: res.data.text,
          webshop_lang: 'EN'
        },
        // run the to json thing
        this.specChanged(res.data.text, 'EN')
      )
    });
  }


  /**
   * Here we parse the raw text to a js object
   */
  specChanged(value, lang) {

    let lines = value.replaceAll('::', 'ZAFFIUS').split('\n')

    let data = []

    lines.forEach(line => {
      if (line.trim() !== '') {

        const nameSpec = line.split(':')

        const name = nameSpec[0].trim().replaceAll('ZAFFIUS', ':')
        if (nameSpec.length > 1) {
          const spec = nameSpec[1].trim().replaceAll('ZAFFIUS', ':')
          data.push({name, spec})
        } else {
          data.push({name, spec: ''})
        }
      }
    })
    let update
    switch (lang) {
      case 'NL':
        update = {specs_raw: value, specs: data}
        break
      case 'EN':
        update = {specs_raw_en_US: value, specs_en_US: data}
        break
      default:
    }

    this.setState(update)
  }

  getData() {
    return new Promise(resolve => {
      axios.get(BE + '/get-product-detail',
        {
          params: {product_id: this.props.product_id}
        }).then(response => {
        resolve(response.data)
      });
    })
  }

  save() {

    let product = {...this.state.product}

    product.specs = JSON.stringify(this.state.specs)
    product.specs_en_US = JSON.stringify(this.state.specs_en_US)

    axios.post(BE + '/set-product', product).then((res) => {
      if (res.data.msg === 'ok') {
        this.savemessage.show({severity: 'success', detail: 'Opgeslagen', closable: false});
      } else {
        this.savemessage.show({sticky: true, severity: 'error', detail: res.data.msg, closable: false});
      }
    }).catch((err) => {
      this.savemessage.show({sticky: true, severity: 'error', detail: err, closable: false});
    })
  }


}

export default ProductSpecs
