import React, {Component} from 'react'
import axios from "axios";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown"
import {Fieldset} from "primereact/fieldset";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';

const BE = process.env.REACT_APP_MTG_BACK



export class Portal extends Component {

  render() {

    return (

      <div className="p-fluid" style={{marginTop: '1rem'}}>
        <Fieldset legend="Widgets">
            <div className="p-field p-grid">
              <label className="p-col-3">Voeg widget toe</label>
              <div className="p-col-5">
                <Dropdown
                  placeholder="Selecteer Een Type"
                  style={{width: '100%'}}
                  value={this.state.selval || 0}
                  options={this.state.all_widgets}
                  onChange={(e) => this.widget(e.value, 'add')}/>
              </div>
            </div>


            {this.state.widgets.map((el, i) => {

              if (el.name === "stemmen-op-muziek") { this.state.music_active = true}

              return <div key={i} className="p-field p-grid">
                <label className="p-col-3"></label>
                <div className="p-col-3" style={{fontWeight: 'bold', paddingLeft: '1rem'}}>
                  {el.name}
                </div>
                <div className="p-col-2" style={{textAlign: 'right'}}>
                  <Button onClick={() => this.widget(el.id, 'up')} disabled={i === 0} className="p-button-secondary" icon="pi pi-angle-up"/>
                  <Button onClick={() => this.widget(el.id, 'down')} disabled={i > (this.state.widgets.length - 2)} className="p-button-secondary"
                          icon="pi pi-angle-down" style={{marginLeft: '0.5rem'}}/>
                  <Button onClick={() => this.widget(el.id,'delete')} className="p-button-danger" icon="pi pi-times" style={{marginLeft: '0.5rem'}}/>
                </div>
              </div>
            })}

        </Fieldset>
        <Fieldset legend="Stemmen op muziek lijst">
          <DataTable value={this.state.music_list}
                    paginator={true}
                    rows={15}
                    editable={true}
                    removableSort={true}
                    scrollHeight="600px"
                    style={{marginTop: '5px'}}>

            <Column field="id" style={{width: '45px'}} header="ID" filter={false} filterMatchMode={'contains'}/>
            <Column field="title" header="Title" filter={true} filterMatchMode={'contains'}/>
            <Column field="artist" header="Artist" filter={true} filterMatchMode={'contains'}/>
            <Column field="category" header="Category" filter={true} filterMatchMode={'contains'}/>
            <Column header="Activeer" body={(row) => {
              return (
                (this.handleCheck(row)) ?
                  <i className="pi pi-power-off" style={{backgroundColor: 'green', padding: '5px', color:'#fff', cursor:"pointer"}}
                    onClick={this.toggleActive.bind(this, row)}
                    >
                  </i> :
                  <i className="pi pi-power-off" style={{backgroundColor: 'red', padding: '5px', color:'#fff', cursor:"pointer"}}
                    onClick={this.toggleActive.bind(this, row)}
                    >
                  </i>
              )
            }} style={{width: '75px', textAlign: 'center'}}/>


          </DataTable>
        </Fieldset>
        <Fieldset legend="Lied toevoegen" style={{marginTop: '1rem'}}>
          <form onSubmit={this.saveSong.bind(this)}>
            <div className="p-field p-grid">  
              <label className='p-col-1'>Titel</label>
              <div className='p-col-4'>
                <InputText id="title" type="text"
                  placeholder="Titel"
                  value={this.state.title}
                  onChange={e => this.itemChanged(e)}
                />
              </div>
            </div>
            <div className="p-field p-grid">  
              <label className='p-col-1'>Artiest</label>
              <div className='p-col-4'>
                <InputText id="artist" type="text"
                  value={this.state.artist}
                  placeholder="Artiest"
                  onChange={e => this.itemChanged(e)}
                />
              </div>
            </div>
            <div className="p-field p-grid">  
              <label className='p-col-1'>Categorie</label>
              <div className='p-col-4'>
                <Dropdown id="category"
                  value={this.state.category}
                  options={this.state.categories}
                  onChange={e => this.itemChanged(e)}
                />
              </div>
            </div>
            <div className="p-field p-grid">  
              <div className='p-col-2'>
                <Button label="Toevoegen" type="submit"/>
              </div>
            </div>
            {this.state.message &&
              <div class="p-message p-component p-message-success p-message-enter-done">
                <div class="p-message-wrapper">
                  <span class="p-message-icon pi  pi-check"></span>
                  <span class="p-message-detail">Lied toegevoegd</span>
                </div>
              </div>
            }
          </form>
        </Fieldset>
      </div>

    );
  }

  state = {
    all_widgets: [],
    widgets: [],
    local_portal_active: null,
    music_active: null,
    music_list: [],
    music_selected: [],
    webshop_id: null,
    title: '',
    artist: '',
    category: '',
    categories: ['christmas', 'spring', 'winter'],
    message: false,
  }

  widget(id, type){
    axios.get(BE + '/tinycms-widget', {
      params: {
        webshop_id: this.props.match.params.webshop_id,
        id,
        type
      }
    }).then(res => {
      this.loadData()
    })

  }


  componentDidMount() {
    this.loadData()
  }

  handleCheck(val) {
      return this.state.music_selected.some(item => val.id === item.music_id);
  }

  itemChanged(e) {
    let newState = {}
    switch (e.target.type) {
      case 'checkbox':
        newState[e.target.id] = e.target.checked
        break;
      default:
        newState[e.target.id] = e.target.value
        break;
    }
    this.setState(newState);
  }

  saveSong(e) {
    e.preventDefault();
    this.setState({message: false});
    let data = {};
    data.title = this.state.title;
    data.artist = this.state.artist;
    data.category = this.state.category;
    data.webshop_id = this.state.webshop_id;

    axios.post(BE + '/tinycms-add-song', data).then(res => {
      this.setState({message: true});
      this.setState({music_selected: res.data.selected, music_list:res.data.list})
      this.setState({title: '', artist: '', category: '' })
    }).catch(error => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });
  }

  toggleActive(row, el) {

    let webshop_id = this.state.webshop_id;

    axios.post(BE + '/tinycms-toggle-music-active', {music_id: row.id, webshop_id:webshop_id}).then(res => {
      this.setState({music_selected: res.data})
    }).catch(error => {
      alert("\nEr is iets fout gegaan!\n\nHerlaadt de pagina en probeer nogmaals.")
    });

  }

  loadData(){
    // Data
    axios.get(BE + '/tinycms-get-settings?webshop_id=' + this.props.match.params.webshop_id).then(res => {

      // Only the ones that are not selected
      let all_widgets = res.data.all_widgets
        .filter(el => !(res.data.widgets.map(el=>el.id).includes(el.id))  )
        .map(el => {return {label: el.name, value: el.id}})

      this.setState({
        local_portal_active: res.data.local_portal_active,
        widgets: res.data.widgets,
        music_list: res.data.music,
        music_selected: res.data.webshop_music,
        webshop_id: res.data.webshop_id,
        all_widgets
      });
    })
  }
}

export default Portal
