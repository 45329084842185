import React from "react"
import {Dialog} from "primereact/dialog"
import {FileUpload} from 'primereact/fileupload';

/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class UploadVouchersDialog extends React.Component {

  render() {
    return <Dialog position="top"
                   header="Upload voucher(s)"
                   visible={this.state.visible}
                   style={{top: '100px', width: '70rem'}}
                   modal={true}
                   onHide={() => {
                     this.setState({
                       visible: false,
                     })
                     this.callback()
                   }}>

      <div className="p-grid">
        <div className="p-col-12">
          Download example .xlsx <a href={process.env.PUBLIC_URL + '/example-voucher-upload.xlsx'}>here.</a>
        </div>
        <div className="p-col-12">
          <FileUpload name="vouchers"
                      accept="text/csv; charset=utf-8"
                      maxFileSize={4000000}
                      multiple={false}
                      auto={false}
                      onBeforeSend={this.onBeforeSend}
                      onUpload={(e) => this.succes(e)}
                      url={process.env.REACT_APP_MTG_BACK + '/upload-vouchers?webshop_id=' + this.props.match.params.webshop_id}/>
        </div>
        <div className="p-col-12">
          {this.state.messages.map((e, i) => {
            return <div key={i}>{e}</div>
          })}
        </div>
      </div>

      <div className="p-grid">
        <div className="p-col-12">
          {/*<ProgressBar value={this.state.progress} style={{visibility: this.state.progressVisible}}/>*/}
        </div>
      </div>

    </Dialog>
  }

  state = {
    visible: false,
    messages: []
  }

  onBeforeSend(req) {
    let api_token = sessionStorage.getItem('api_token');
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + api_token);
    return req;
  }

  succes(e) {
    let data = JSON.parse(e.xhr.response);
    let messages = [];
    if (data === true) {
      messages = ['All good baby!']
    } else {
      messages = data.map((e) => {
        return e.msg + '. row: ' + e.row + ' username: ' + e.name
      })
    }
    this.setState({messages})
  }

  callback = null;

  open(callback) {
    this.callback = callback
    this.setState({visible: true})
  }

}

export default UploadVouchersDialog
