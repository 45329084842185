import React from "react"
import {Dialog} from "primereact/dialog"

import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import axios from "axios";
import {AutoComplete} from 'primereact/autocomplete';

const BACKEND = process.env.REACT_APP_MTG_BACK

/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class DialogMeeting extends React.Component {

    render() {
        return (
            <Dialog header="Add meeting" visible={this.state.visible}
                    style={{top: '100px', width: '40rem'}} modal={true}
                    onHide={() => this.close()}
                    onShow={() => {

                    }}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="p-grid">
                        <div className="p-col-4">Host</div>
                        <div className="p-col-8">
                            <AutoComplete dropdown
                                          value={this.state.meeting.host_id}
                                          required
                                          suggestions={this.state.filteredHosts}
                                          completeMethod={this.searchHost}
                                          field="name"
                                          disabled={this.state.meeting.id ? true : false}
                                          onChange={(e) => this.setState({
                                              meeting: {
                                                  ...this.state.meeting,
                                                  host_id: e.target.value
                                              }
                                          })}/>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-4">Onderwerp</div>
                        <div className="p-col-8">
                            <InputText value={this.state.meeting.topic} required onChange={(e) => {
                                this.setState({meeting: {...this.state.meeting, topic: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-4">Quiz (optioneel)</div>
                        <div className="p-col-8">
                            <InputText value={this.state.meeting.quiz_code}
                                       tooltip="Vul hier een complete link in zoals //kahoot.it/challenge/123?challenge-id=456"
                                       tooltipOptions={{position: 'left'}}
                                       onChange={(e) => {
                                this.setState({meeting: {...this.state.meeting, quiz_code: e.target.value}})
                            }}/>
                        </div>
                    </div>
                    <div className="p-grid" style={{marginBottom: '2em'}}>
                        <div className="p-col-4"></div>
                        <div className="p-col-8">
                            <Button label="Save"/>
                        </div>
                    </div>
                </form>
            </Dialog>
        )
    }

    state = {
        visible: false,
        hosts: [],
        filteredHosts: null,
        meeting: {host_id: '', topic: ''},
    }

    constructor(props) {
        super(props);
        this.searchHost = this.searchHost.bind(this);
    }

    open(id) {
        if (id) {
            axios.get(process.env.REACT_APP_MTG_BACK + '/get-meeting',
                {
                    params: {id: id}
                }).then(response => {
                this.setState({meeting: response.data})
            });
        }
        this.setState({visible: true})
        this.getHosts()
    }

    close() {
        this.setState({
            visible: false,
            meeting: {host_id: '', topic: ''},
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let meeting = this.state.meeting;
        meeting.webshoporder_id = this.props.match.params.webshop_id;
        axios.post(BACKEND + '/save-meeting', meeting).then((res) => {
            if (res.data.msg === 'ok') {
                //     this.props.messages.show({severity: 'success', detail: 'Opgeslagen', closable: false});
                this.close();
                this.props.onClose('success', 'De meeting is succesvol opgeslagen.')
            } else {
                //     this.props.messages.show({sticky: true, severity: 'error', detail: res.data.msg, closable: false});
            }
        }).catch((err) => {
            // this.props.messages.show({sticky: true, severity: 'error', detail: err, closable: false});
            console.log(err)
        })
    }

    getHosts() {
        axios.get(BACKEND + '/get-hosts', {params: {webshop_id: this.props.match.params.webshop_id}}).then((response) => {
            if (response.data.msg === 'ok') {
                let hosts = response.data.rows.map((item, i) => {
                    return {
                        name: item.first_name + " " + item.last_name + " (" + item.email + ")",
                        code: item.id
                    };
                });
                this.setState({'hosts': hosts})
            } else {
                alert(response.data)
            }

        }).catch((error) => {
        });

    }

    searchHost(event) {
        setTimeout(() => {
            let filteredHosts;
            if (!event.query.trim().length) {
                filteredHosts = [...this.state.hosts];
            } else {
                filteredHosts = this.state.hosts.filter((host) => {
                    return host.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({filteredHosts});
        }, 250);
    }
}

export default DialogMeeting
