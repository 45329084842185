import React from 'react'
import Menu from '../components/Menu'
import {Fieldset} from 'primereact/fieldset'
import Partners from '../components/data/Partners'
import Auth from '../auth/Auth'

class PagePartners extends Auth {

  render() {
    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset legend="Partners" style={{fontSize: '1.3rem'}}>
          <Partners/>
        </Fieldset>
      </div>
    )
  }
}
export default  PagePartners

