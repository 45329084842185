import React, {Component} from 'react'
import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import axios from 'axios'

import DialogUploadImages from '../dialogs/DialogUploadImages'
import DialogAreYouSure from '../dialogs/DialogAreYouSure'
import DialogLeverancierImages from "../dialogs/DialogLeverancierImages";
import DialogImagePreview from "../dialogs/DialogImagePreview";

const BE = process.env.REACT_APP_MTG_BACK
const STORAGE = process.env.REACT_APP_MTG_STOR

const style = {
  ImageWrapper: {
    border: 'solid 1px gainsboro',
    textAlign: 'center',
    padding: '0.4rem'
  },
  ImgActionWrapper: {
    border: 'solid 1px gainsboro',
    height: '2rem',
    lineHeight: '1.8rem'
  },
  NewImage: {
    width: '250px',
    height: '250px',
    paddingTop: '40px',
  },
  ButtonIcon: {
    height: '1.7rem', width: '1.7rem', float: 'right', margin: '0.1rem'
  }
}

let errSrc = '/media/images/placeholders/250x250.png'

class ProductImages extends Component {

  render() {

    return (
      <div>
        <DialogUploadImages ref={this.DialogUploadImages}/>
        <DialogAreYouSure ref={this.DialogAreYouSure}/>
        <DialogLeverancierImages ref={this.DialogLeverancierImages}/>
        <DialogImagePreview ref={this.DialogImagePreview}/>

        <div className="p-grid">
          <div className="p-col-6">
            <Button className={this.state.waitingForSync ? 'p-button-secondary' : 'p-button-success'}
                    tooltip="Download of vernieuw Odoo plaatje"
                    icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-circle-down'}
                    onClick={() => this.syncProductWithOdoo()}/>

          </div>
          <div className="p-col-6">
            <Button icon="pi pi-images"
                    tooltip="Vind leveranciers plaatjes"
                    className="p-button-info"
                    style={{float: 'right'}}
                    onClick={() => this.DialogLeverancierImages.current.open(this.props.product_id, () => {
                      this.getData()
                    })}
            />
          </div>
        </div>

        <div className="p-grid" style={{fontSize: '1rem'}}>
          {this.state.images.map((img, index) => {
            return (<div key={index} className="p-col-4">
              <div style={style.ImageWrapper}>

                {(img.id === 0 && this.state.loading)
                  ? <div style={{height: '250px', width: '250px', marginBottom: '5px'}}>
                    <i className="pi pi-spin pi-spinner" style={{'fontSize': '80px', marginTop: '85px'}}></i>
                  </div>
                  : <img alt=""
                         style={{cursor: 'pointer'}}
                         onClick={() => {
                           this.DialogImagePreview.current.open(`${img.id}_${img.product_id}.jpg`)
                         }}
                         src={`${STORAGE}/img/odoo-producten/250x250/${img.id}_${img.product_id}.jpg${img.id ? '' : ('?' + new Date().getTime())}`}
                         onError={e => e.target.src = errSrc}
                  />
                }
                <div style={style.ImgActionWrapper}>

                    <div style={{textAlign: 'left'}}>
                      {/*=========== Push to Odoo =============*/}
                      {img.id !== 0 &&
                      <Button icon="pi pi-arrow-circle-up"
                              tooltip="Upload plaatje naar Odoo"
                              className="p-button-info"
                              onClick={() => this.pushImageToOdoo(index)}
                              style={style.ButtonIcon}/>
                      }
                      {/*=========== Delete image butt =============*/}
                      {img.id !== 0 &&
                      <Button icon="pi pi-fw pi-trash"
                              className="p-button-warning"
                              tooltip="Verwijder plaatje"
                              onClick={() => this.deleteImage(index)}
                              style={style.ButtonIcon}/>
                      }
                      {/*=========== Move right =============*/}
                      {index < (this.state.images.length - 1) &&
                      <Button icon="pi pi-chevron-right"
                              className="p-button-success"
                              tooltip="Verplaats naar rechts"
                              onClick={() => this.move(index,'right')}
                              style={style.ButtonIcon}/>
                      }
                      {/*=========== Move left =============*/}
                      {index > 0 &&
                      <Button icon="pi pi-chevron-left"
                              className="p-button-success"
                              tooltip="Verplaats naar links"
                              onClick={() => this.move(index,'left')}
                              style={style.ButtonIcon}/>
                      }
                      {/*=========== Checkbox Ambiance Img =============*/}
                      {img.id !== 0 &&
                      <Checkbox inputId={'cb' + img.id + '_' + img.product_id}
                                style={{margin: '0.14rem 0.30rem'}}
                                onChange={() => {
                                  this.setAmbianceImage(index)
                                }}
                                checked={img.ambiance_image === 1}/>
                      }
                      <label htmlFor={'cb' + img.id + '_' + img.product_id} className="p-checkbox-label">
                        {img.id === 0 ? 'Odoo Image' : 'Ambiance Image'}
                      </label>
                    </div>

                </div>
              </div>
            </div>)
          })}
          <div className="p-col-4">
            <div style={style.ImageWrapper}>
              <div style={{width: '250px', height: '250px', border: 'solid 1px gainsboro', display: 'inline-block'}}>
                <Button icon="pi pi-fw pi-upload"
                        className="p-button-secondary p-button-rounded"
                        onClick={() => this.uploadImages()}
                        style={{fontSize: '75px', marginTop: '40px'}}/>
              </div>
              <div style={{...style.ImgActionWrapper, marginTop: '5px'}}>
                New image
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  state = {
    images: [],
    loading: false,
    waitingForSync: false
  }

  constructor(props) {
    super(props)
    this.setAmbianceImage = this.setAmbianceImage.bind(this)
    this.DialogUploadImages = React.createRef()
    this.DialogAreYouSure = React.createRef()
    this.DialogLeverancierImages = React.createRef()
    this.DialogImagePreview = React.createRef()

  }

  componentDidMount() {
    this.getData()
  }

  move(index,direction){
    axios.get(BE + '/product-image-reorder', {
      params: {
        index: index,
        product_id: this.state.images[index].product_id,
        direction
      }
    }).then(res => {
      this.getData()
    });

  }

  pushImageToOdoo(index) {

    this.DialogAreYouSure.current.open('Weet je zeker dat je dit plaatje naar Odoo wil pushen?', () => {

      this.setState({loading: true})

      axios.get(BE + '/push-image-to-odoo', {
        params: {
          id: this.state.images[index].id,
        }
      }).then(res => {
        this.getData()
      });
    })

  }

  deleteImage(index) {
    this.DialogAreYouSure.current.open('Weet je zeker dat je dit plaatje wil verwijderen?', () => {
      axios.get(BE + '/delete-image', {
        params: {
          id: this.state.images[index].id,
        }
      }).then(res => {
        this.getData()
      });
    })
  }

  getData() {
    axios.get(BE + '/get-product-images',
      {
        params: {product_id: this.props.product_id}
      }).then(response => {
      this.setState({images: response.data, loading: false})
    });
  }

  uploadImages() {
    this.DialogUploadImages.current.open(this.props.product_id, () => {
      this.getData()
    })
  }

  setAmbianceImage(index) {

    let img = this.state.images[index];

    axios.get(BE + '/set-ambient-image', {
      params: {
        id: img.id,
        product_id: img.product_id,
        ambiance_image: img.ambiance_image === 0 ? 1 : 0
      }
    }).then(res => {
      this.getData()
    });
  }
  syncProductWithOdoo() {
    this.setState({waitingForSync: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-one-product?id='+this.props.product_id).then((res) => {
      this.setState({waitingForSync: false});
      this.getData()
    })
  }

}

export default ProductImages
