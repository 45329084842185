const options = [
  {label: 'Altijd', value: 'always'},
  {label: 'Ingekocht', value: 'if_purchased'},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

