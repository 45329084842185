import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";
import moment from "moment";
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


class DialogVoucherOrders extends Component {
  expandedRows() {

  }

  setExpandedRows(data) {

  }

  onRowExpand() {

  }

  onRowCollapse() {

  }

  rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <DataTable value={data.orderLines} >
          <Column style={{width:'50%'}}
                  body={(row)=>row.display_name.trim()}
                  field="display_name" header="Product"/>
          <Column field="count" header="Count"/>
          <Column field="price_in_eco" header="Total eco" body={(row)=>{
            return row.price_in_eco * row.count
          }}/>
        </DataTable>
      </div>
    );
  }

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: '60em'}}
      position="top"
      modal={true}
      onHide={() => {
        this.setState({visible: false})
      }}>


      <div className="p-grid">
        <div className="p-col" style={{whiteSpace: 'pre'}}>

          <DataTable value={this.state.orders}
                     expandedRows={this.state.expandedRows}
                     onRowToggle={e => this.setState({expandedRows:e.data})}
                     rowExpansionTemplate={this.rowExpansionTemplate}
                     dataKey="local_id">
            <Column expander style={{width: '3em'}}/>
            <Column header="Local id" field="local_id"/>
            <Column header="Odoo id" body = {(row) =>  {
              return row.orderLines[0].odoo_order_id
            }}/>
            <Column header="Odoo number" body = {(row) =>  {
              return row.orderNumber
            }}/>
            <Column header="Order date"
                    className="t-center"
                    field="write_date"
                    body={(row) => {
                      return moment(row.write_date).format('YYYY-MM-DD HH:mm')
                    }}
            />
          </DataTable>
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col">
          <Button style={{width: '10em'}}
                  label="OK"
                  onClick={() => {
                    this.callback()
                    this.setState({visible: false})
                  }}/>
        </div>
        <div className="p-col">
          <Button style={{width: '10em', float: 'right'}} label="Cancel" className="p-button-secondary"
                  onClick={() => {
                    this.setState({visible: false})
                  }}/>

        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    text: '',
    header: '',
    position: 'top',
    width: '25em',
    orders: []

  }

  callback = null

  open(voucher, callback, type) {
    if(!type){
      type='gebruiker'
    }
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-voucher-orders',{
      params:{
        id:voucher.id,
        type: type
      }} ).then((response) => {
      let expandedRows = {}

      expandedRows[response.data[0].local_id] = true
      this.setState({
        orders: response.data,
        expandedRows
      })
    })


    this.callback = callback
    this.setState({
      visible: true,
      header: voucher.naam
    })
  }
}

export default DialogVoucherOrders
