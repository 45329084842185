const options = [
  {label: 'Webshop', value: 1},
  {label: 'Dropship', value: 9},
  {label: 'Kerstpak', value: 22},
]

const lookup = (value) => {
  let i = options.filter((i) => i.value === value);
  return i.length ? i[0].label : undefined;
}

export  {options, lookup}

