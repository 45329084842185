import React from 'react'
import {Dialog} from 'primereact/dialog'
import axios from 'axios'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Button} from "primereact/button";


const BACKEND = process.env.REACT_APP_MTG_BACK

const fields = ['username','voucher_balance','title', 'initials', 'firstname', 'infix', 'lastname', 'street', 'sc_street_number', 'street2', 'zip', 'city', 'country_id', 'email', 'private_email', 'lang', 'functie']

/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class EditVoucherDialog extends React.Component {

  render() {
    if (!this.state.voucher) return null

    return <Dialog header={'Edit voucher ' + this._getVoucherNo(this.state.voucher)}
                   visible={this.state.visible}
                   position="top"
                   style={{width: '40rem'}} modal={true}
                   onHide={() => this.close()}
                   onShow={() => {
                     // need other thread.. dunno why
                     // this.numberRef.current.focus()
                   }}>

      {fields.map((el, i) => {

        return <div className="p-grid" key={i}>
          <div className="p-col-3">{el}</div>
          <div className="p-col-9">

            {!['country_id'].includes(el) &&
            <InputText style={{width: '100%'}}
                       onChange={(e) => {
                         let clone = Object.assign({}, this.state.voucher)
                         clone[el] = e.target.value
                         this.setState({voucher: clone})
                       }}
                       value={this.state.voucher[el] !== null ? this.state.voucher[el] : ''}
            />}

            {['country_id'].includes(el) &&
            <Dropdown style={{width: '100%'}}
                      value={this.state.voucher.country_id}
                      options={this.state.countries}
                      onChange={(e) => {
                        let clone = Object.assign({}, this.state.voucher)
                        clone.country_id = e.value
                        this.setState({voucher: clone})
                      }}
                      placeholder="No country defined!"
            />}
          </div>
        </div>


      })}
      <div className="p-grid">
        <div className="p-col-3"></div>
        <div className="p-col-9">
          <Button label="Save" onClick={()=>{
            // do not save the webshoporder
            let data = Object.assign({},this.state.voucher)
            delete data.webshoporder
            axios.put(BACKEND + '/put-voucher',data).then(res => {
              // Pass back the changed voucher
              this.callback(res.data.rows[0])
              this.close()
            })
          }}></Button>
        </div>
      </div>
    </Dialog>
  }

  state = {
    visible: false,
    voucher: null,
    voucherCode: '',
    progress: 0,
  }

  _getVoucherNo(voucher){

    return voucher.webshoporder.local_abbr + voucher.id.toString().padStart(7,0) + ' (' + voucher.username + ')'
  }

  callback

  async open(id, callback) {

    this.callback = callback

    const res = await axios.get(BACKEND + '/get-voucher?id=' + id)
    const countries = await axios.get(BACKEND + '/get-webshopcountries?id=' + res.data.webshoporder_id)

    this.setState({
      voucher: res.data,
      voucherCode: res.data.username,
      countries: countries.data.map(el => {
        return {label: el.name, value: el.id}
      }),
      visible: true
    })

  }

  close() {
    this.setState({
      visible: false,
      voucher: null,
      voucherCode: '',
    })
  }
}

export default EditVoucherDialog
