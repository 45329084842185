import React, {Component} from 'react'
import axios from 'axios'
import moment from 'moment'

let lightSize = 8
let lightStyle = {
  width: lightSize + 'px',
  height: lightSize + 'px',
  marginTop: '1px',
  borderRadius: (lightSize / 2) + 'px',
  opacity: '0.1',
  border: 'solid 1px'
}

let deviceStyle = {
  position: 'absolute',
  top: '6px',
  display: 'block',
  border: 'solid 1px #ddd',
  backgroundColor: '#eee',
  padding: '1px 3px',
  borderRadius: (lightSize / 2) + 'px'
}


class TrafficLight extends Component {
  render() {
    return (
      <ul style={{...deviceStyle, ...this.props.style}}>
        <li style={{
          ...lightStyle,
          backgroundColor: '#ff0000',
          borderColor: '#7d0000',
          opacity: this.state.red ? 1 : 0.2
        }}/>
        <li style={{
          ...lightStyle,
          backgroundColor: '#ffff00',
          borderColor: '#ffa900',
          opacity: this.state.yellow ? 1 : 0.2
        }}/>
        <li style={{
          ...lightStyle,
          backgroundColor: '#00ff00',
          borderColor: '#007d00',
          opacity: this.state.green ? 1 : 0.2
        }}/>
      </ul>
    )
  }

  state = {
    job_started: 0,
    red: false,
    yellow: false,
    green: false
  }

  componentDidMount() {

    this.getData(true)

    // Check every minute
    this.intervalEveryMinute = setInterval(() => {
      this.getData(false)
    }, 600000)
  }

  componentWillUnmount() {

    clearInterval(this.intervalEveryMinute);
    clearInterval(this.intervalEverySecond);
  }

  getData(runOnce) {
    axios.get(process.env.REACT_APP_MTG_BACK + '/reports-cronlock').then(res => {
      this.setState({
        job_started: res.data.job_started
      }, () => {
        this.calcTrafficLight()
        if (runOnce) {
          this.intervalEverySecond = setInterval(() => {
            this.calcTrafficLight()
          }, 1000)
        }
      })
    });

  }

  calcTrafficLight() {

    let runningFor = (new Date().valueOf() - moment(this.state.job_started).valueOf()) / 1000

    let state = {red: false, yellow: false, green: false}
    if (runningFor > (10 * 60)) {
      this.setState({...state, red: true})
    } else if (runningFor > (5 * 60)) {
      this.setState({...state, yellow: true})
    } else {
      this.setState({...state, green: true})
    }
  }
}


export default TrafficLight
