import React, {Component} from 'react';
import {FileService} from '../../services/file-service';

import {saveAs} from 'file-saver';
import {SplitButton} from 'primereact/splitbutton';




class DownloadVouchers extends Component {
  items = [
    {
      label: 'Vouchers',
      icon: 'pi pi-key',
      command: () => {
        this.downloadVouchers()
      }
    },
    {
      label: 'Invitations',
      icon: 'pi pi-info',
      command: () => {
      this.downloadInvitations()
      }
    },
    {
      label: 'Reminders',
      icon: 'pi pi-bell',
      command: () => {
        this.downloadReminders()
      }
    },
  ]
  render() {
    return (
      <div style={{textAlign: 'right'}}>

        <SplitButton label="Download"
                     icon="pi pi-cloud-download"
                     onClick={()=>{}}
                     model={this.items}/>

        <label>{this.state.downloading ? 'Downloading in progress' : ''}</label>
      </div>
    )
  };


  constructor(props) {
    super(props);
    this.fileService = new FileService();
    this.state = {downloading: false};
  }


  downloadVouchers = () => {
    this.setState({downloading: true});
    this.fileService.getVoucersFromServer(this.props.webshop).then((response) => {
      this.setState({downloading: false});
      saveAs(response.data, this.props.webshop.slug + '.xlsx');
    })
  };
  downloadInvitations = () => {
    this.setState({downloading: true});
    this.fileService.getInvitationsFromServer(this.props.webshop).then((response) => {
      this.setState({downloading: false});
      saveAs(response.data, this.props.webshop.slug + '.xlsx');
    })
  };
  downloadReminders = () => {
    this.setState({downloading: true});
    this.fileService.getRemindersFromServer(this.props.webshop).then((response) => {
      this.setState({downloading: false});
      saveAs(response.data, this.props.webshop.slug + '.xlsx');
    })
  };



}

export default DownloadVouchers
