import React, {Component} from 'react'

import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import ProductProperties from '../properties/ProductProperties';
import ProductDescriptions from '../properties/ProductDescriptions'
import ProductImages from '../properties/ProductImages';

import ProductRibbons from "../properties/ProductRibbons";
import ProductSpecs from "../properties/ProductSpecs";


class DialogProductProperties extends Component {

  render() {
    return <Dialog
      header={`Product: ${this.state.header}`}
      visible={this.state.visible}
      style={{width: '65em'}}
      position={'top'}
      modal={true}
      onHide={() => {
        this.setState({visible: false})
      }}>
      <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>

        <TabPanel header="Eigenschappen">
          <ProductProperties product_id={this.state.product_id} webshoporder_id={this.state.webshoporder_id} onsync={this.onsync}/>
        </TabPanel>

        <TabPanel header="Afbeeldingen">
          <ProductImages product_id={this.state.product_id}/>
        </TabPanel>

        <TabPanel header="Omschrijving">
          <ProductDescriptions product_id={this.state.product_id}/>
        </TabPanel>

        <TabPanel header="Specs">
          <ProductSpecs product_id={this.state.product_id}/>
        </TabPanel>

        {/*<TabPanel headerStyle={{display:this.state.webshoporder_id ? 'block' : 'none'}} header="Categorieën">*/}
        {/*  <ProductWebshopProperties product_id={this.state.product_id} webshoporder_id={this.state.webshoporder_id}/>*/}
        {/*</TabPanel>*/}

        <TabPanel header="Ribbons">
          <ProductRibbons product_id={this.state.product_id}/>
        </TabPanel>


      </TabView>

    </Dialog>

  }

  state = {
    visible: false,
    product_id: null,
    header: null,
    webshoporder_id: undefined,
    messages: null,
    activeIndex: 0
  }

  constructor(props) {
    super(props);
    this.onsync = this.onsync.bind(this)
  }

  onsync() {
    this.setState({visible: false})
  }

  open(product_id, header, webshoporder_id) {

    this.setState({
      visible: true,
      header,
      product_id,
      webshoporder_id,
      activeIndex: 1
    })
  }
}

export default DialogProductProperties
