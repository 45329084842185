import axios from 'axios';
class RestService {
  getRestClient() {
    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        baseURL: process.env.REACT_APP_MTG_BACK,
        timeout: 120000,
        // headers: {
        //   'Content-Type': 'application/json'
        // },
      });
    }
    return this.serviceInstance;
  }
}

export default (new RestService());
