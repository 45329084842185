import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";

class DialogAlert extends Component {

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: this.state.width}}
      position={this.state.position}
      modal={true}
      onHide={() => {
        if(this.state.visible) {
          this.close()
        }
      }}
    >
      <div className="p-grid">
        <div className="p-col" style={{ whiteSpace: 'pre' }}>
          {this.state.text}
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col">
          <Button style={{width: '10em'}}
                  label="OK"
                  onClick={() => {
                    this.close()
                  }}/>
        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    text: '',
    header: '',
    position: 'top',
    width: '25em'
  }

  callback = null

  close(){
    this.setState({visible:false})
    if(typeof this.callback === 'function'){
      this.callback()
    }
  }

  open(text, callback, header, position, width) {
    if(!position)
      position = 'top'
    if(!width)
      width = '25em'
    this.callback = callback
    this.setState({
      visible: true,
      text,
      header,
      position,
      width
    })
  }
}

export default DialogAlert