import React from 'react'
import Menu from '../components/Menu'
import {Fieldset} from 'primereact/fieldset'
import Auth from '../auth/Auth'
import axios from "axios";

const pp = (json) => {
  if (typeof json != 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  // eslint-disable-next-line
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
    var cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return '<span class="' + cls + '">' + match + '</span>';
  });
}

class PageTypeform extends Auth {

  state = {
    form: '',
    lead: ''
  }

  render() {
    return (
      <div>
        <Menu {...this.props}/>
        <Fieldset legend="Typeform" style={{fontSize: '1.3rem'}}>

          <pre style={{fontSize: '0.8rem'}} dangerouslySetInnerHTML={{__html: pp(this.state.form)}}></pre>
          <pre style={{fontSize: '0.8rem'}} dangerouslySetInnerHTML={{__html: pp(this.state.lead)}}></pre>
        </Fieldset>
      </div>
    )
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/typeform/get-lead'
    ).then(res => this.setState({lead: res.data}))
    axios.get(process.env.REACT_APP_MTG_BACK + '/typeform/get-form'
    ).then(res => this.setState({form: res.data}))
    super.componentDidMount()
  }
}

export default PageTypeform

