import React from "react"
import {Dialog} from "primereact/dialog"
import {FileUpload} from 'primereact/fileupload';
import {Messages} from "primereact/messages";

/**
 *
 * The dialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
class ImportProductsDialog extends React.Component {

  render() {
    return <Dialog position="top"
                   header="Importeer producten"
                   visible={this.state.visible}
                   style={{top: '100px', width: '70rem'}}
                   modal={true}
                   onHide={() => {
                     this.setState({
                       visible: false,
                     })
                     this.callback()
                   }}>

      <div className="p-grid">
        <div className="p-col-12">
          Download example .xlsx <a href={process.env.PUBLIC_URL + '/example-product-import.xlsx'}>here.</a>
        </div>
        <div className="p-col-12">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-col-12">
          <FileUpload name="products"
                      accept="text/csv; charset=utf-8"
                      maxFileSize={4000000}
                      multiple={false}
                      auto={false}
                      onBeforeSend={this.onBeforeSend}
                      onUpload={(e) => this.succes(e)}
                      url={process.env.REACT_APP_MTG_BACK + '/import-webshoplines?webshoporder_id=' + this.props.match.params.webshop_id}/>
        </div>
      </div>

    </Dialog>
  }

  state = {
    visible: false
  }


  onBeforeSend(req) {
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + sessionStorage.getItem('api_token'))
  }

  succes(e) {
    this.messages.clear();
    let data = JSON.parse(e.xhr.response);

    if (data.errors) {
      data.errors.forEach(error => this.messages.show({
        sticky: true,
        severity: 'error',
        detail: error // from server
      }));
    }

    if (data.count) {
      this.messages.show({
        sticky: true,
        severity: 'success',
        detail: (data.count - data.errors.length) + " producten zijn succesvol gekoppeld."
      });
    }
  }


  callback = null;

  open(callback) {
    this.callback = callback
    this.setState({visible: true})
  }

}

export default ImportProductsDialog
