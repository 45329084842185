import React, {Component} from 'react'
import {Dialog} from 'primereact/dialog'
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner'
import axios from "axios";
import {Button} from "primereact/button";

const BE = process.env.REACT_APP_MTG_BACK

class DialogLeverancierImages extends Component {

  getLoading() {
    return <div style={{width: '1171', height: '520px'}}>
      <ProgressSpinner style={{height: '100px', left: '535px', top: '210px'}}/>
      <span style={{display: 'block', position: 'absolute', left: '565px', top: '262px'}}>
        Working...
      </span>
    </div>
  }

  getImages() {
    return this.state.images.map((rst, i) => {
      return <div className="p-col-3" key={i} style={{textAlign: 'center'}}>
        <div className="p-grid" style={{margin: '10px', border: 'solid 1px gainsboro'}}>
          <div className="p-col-12">
            <img alt="" src={rst.fit} style={{cursor: 'pointer'}}
                 onClick={() => {
                   this.setState({showOriginal: i})
                 }}/>
          </div>
          <div className="p-col-12">
            <label htmlFor={'image_' + i} className="p-checkbox-label">Use:{' '}</label>
            <Checkbox inputId={'image_' + i}
                      checked={this.state.selectedImages[i] ? true : false}
                      onChange={() => {
                        let clone = {...this.state.selectedImages}
                        clone[i] ? delete clone[i] : clone[i] = rst.filename
                        this.setState({selectedImages: clone})
                      }}/>
          </div>
        </div>
      </div>
    })
  }

  getOriginal() {
    return <div className="p-grid">
      <div className="p-col-12">
        <Button icon="pi pi-times"
                className="p-button-warning"
                style={{float: 'right'}}
                onClick={() => this.setState({showOriginal: null})}
        />
      </div>
      <div className="p-col-4" style={{textAlign: 'center', border: 'solid gainsboro', borderWidth: '1px 0 1px 1px'}}>
        <h3>Orgineel</h3>
        <img alt="" src={this.state.images[this.state.showOriginal].resize}/>
      </div>
      <div className="p-col-4" style={{textAlign: 'center', border: 'solid 1px gainsboro'}}>
        <h3>Als ambience</h3>
        <img alt="" src={this.state.images[this.state.showOriginal].ambience}/>
      </div>
      <div className="p-col-4" style={{textAlign: 'center', border: 'solid gainsboro', borderWidth: '1px 1px 1px 0px'}}>
        <h3>Als product</h3>
        <img alt="" src={this.state.images[this.state.showOriginal].fit}/>
      </div>
    </div>
  }

  getBody() {
    return <div>
      <div className="p-grid">
        {this.getImages()}
      </div>

      <div className="p-grid">
        <div className="p-col-6"></div>
        <div className="p-col-4">
          <label htmlFor="selall" className="p-checkbox-label">Select all</label>
          <Checkbox style={{marginLeft: '1rem'}}
                    inputId="selall"
                    checked={this.state.selectAll}
                    onChange={(e) => {
                      const selected = e.checked ? this.state.images.map(rst => rst.filename) : []
                      this.setState({
                        selectedImages: selected,
                        selectAll: e.checked
                      })
                    }}/>
        </div>
        <div className="p-col-2">
          <Button label="Process..."
                  style={{width: '100%'}}
                  onClick={() => this.process()}
          />
        </div>
      </div>
    </div>
  }

  getError() {
    return <div>
      {/*Geen leveranciers plaatjes gevonden voor dit product.*/}
      {this.state.error}
    </div>
  }


  render() {
    return (
      <Dialog
        header={`Leveranciers plaatjes`}
        position={'top'}
        visible={this.state.visible}
        style={{width: '75rem'}}
        modal={true}
        onHide={() => {
          this.close()
        }}>

        {this.state.loading
          ? this.getLoading()
          : (this.state.showOriginal !== null)
            ? this.getOriginal()
            : (!this.state.error)
              ? this.getBody()
              : this.getError()
        }

      </Dialog>

    )
  }


  state = {
    visible: false,
    loading: true,
    product_id: null,
    images: [],
    showOriginal: null,
    selectedImages: {},
    selectAll: false,
    error: null
  }

  callback = null

  process() {

    const {product_id, selectedImages} = this.state
    this.setState({loading: true})
    axios.post(BE + '/process-supplier-images',
      {
        product_id,
        selectedImages
      }).then(res => {

      this.succes()
    })

  }

  succes() {
    this.callback()
    this.close()
  }

  open(product_id, callback) {
    this.callback = callback

    axios.get(process.env.REACT_APP_MTG_BACK + '/get-supplier-images?product_id=' + product_id).then(res => {
      if (typeof res.data !== 'string' && res.data.length) {
        this.setState({
          images: res.data,
          loading: false
        })
      } else {
        if (typeof res.data === 'string') {
          this.setState({error: res.data, loading: false})
        } else {
          this.setState({error: 'Geen leveranciers plaatjes gevonden voor dit product.', loading: false})
        }
      }
    })

    this.setState({
      visible: true,
      product_id
    })

  }

  close() {
    this.setState({
      visible: false,
      product_id: null,
      images: [],
      showOriginal: null,
      loading: true,
      selectedImages: {},
      selectAll: false,
      error: null
    })
  }
}

export default DialogLeverancierImages
