import React, {Component} from 'react'
import {TabView, TabPanel} from 'primereact/tabview';
import Settings from './Settings'
import Meetings from './Meetings'
import PubQuizzes from './PubQuizzes'
import Messages from './Messages'
import Music from './Music'

export class Index extends Component {

  render() {

    return (
      <div>
        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
          <TabPanel header="Kerstmarkt" >
            <Settings {...this.props}/>
          </TabPanel>
          <TabPanel header="Borrelen">
            <Meetings {...this.props}/>
          </TabPanel>
          <TabPanel header="Pub Quizzen" >
            <PubQuizzes {...this.props}/>
          </TabPanel>
          <TabPanel header="Wensboom" >
            <Messages {...this.props}/>
          </TabPanel>
          <TabPanel header="Muziek">
            <Music {...this.props}/>
          </TabPanel>
        </TabView>

      </div>
    );
  }


  state = {
    activeIndex: 0
  }

}

export default Index
