import React from "react"
import Index from '../components/virtualmarkets/Index'
import WebshopFieldset from '../components/WebshopFieldset'
import Menu from "../components/Menu";
import Auth from '../auth/Auth'
import axios from "axios";

class PageVirtualMarkets extends Auth {
    state = {
        webshop: null
    }

    constructor(props) {

        super(props);
        this.loadWebshopProps();
    }

    loadWebshopProps() {

        axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshop',
            {params: {webshop_id: this.props.match.params.webshop_id}}
        ).then((response) => {
            this.setState({webshop: response.data})
        });
    }

    render() {
        if (!this.state.webshop) return null;
        return (
            <div>
                <Menu {...this.props}/>
                <WebshopFieldset type="virtual_markets" webshop={this.state.webshop}>
                    <Index  {...this.props} webshop={this.state.webshop}/>
                </WebshopFieldset>
            </div>
        )
    }
}

export default PageVirtualMarkets
