import React, {Component} from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {ListBox} from 'primereact/listbox'

import axios from 'axios'

class DialogCloneWebshop extends Component {

  render() {
    return <Dialog
      header={'Maak webshop aan als:'}
      visible={this.state.visible}
      style={{width: '35em'}}
      modal={true}
      onHide={() => {
        this.close()
      }}>
      {/* Content */}
      <div className="p-grid">
        <div className="p-col" style={{whiteSpace: 'pre'}}>
          <ListBox listStyle={{maxHeight: '15.7rem'}}
                   style={{width:'100%'}}
                   optionLabel="display_name"
                   optionValue="id"
                   options={this.state.rows}
                   value={this.state.selectedWebshopId}
                   onChange={(e) => this.setState({selectedWebshopId: e.value})}
          />

        </div>
      </div>
      {/* Buttons */}
      <div className="p-grid">
        <div className="p-col">
          <Button style={{width: '10em'}}
                  disabled={this.state.selectedWebshopId ? false : true}
                  label="OK"
                  onClick={() => {
                    this.okClicked()
                  }}/>
        </div>
        <div className="p-col">
          <Button style={{width: '10em'}} label="Cancel" className="p-button-secondary"
                  onClick={() => {
                    this.setState({visible: false})
                  }}/>

        </div>
      </div>
    </Dialog>

  }

  state = {
    visible: false,
    rows: [],
    selectedWebshopId: null,
    display_name: ''
  }

  callback = null

  getWebshops() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-webshops').then(res => {

      // Sort on name
      res.data.sort(function(a, b) {
        if (a.display_name.toLowerCase() < b.display_name.toLowerCase()) return -1;
        if (a.display_name.toLowerCase() > b.display_name.toLowerCase()) return 1;
        return 0;
      });

      this.setState({
        rows: res.data
      })
    });
  }

  okClicked(){
    this.callback(this.state.selectedWebshopId)
    this.close()
  }

  close(){
    this.setState({
      visible: false,
      selectedWebshopId: null
    })
  }

  open(display_name, callback) {
    this.callback = callback

    this.getWebshops()

    this.setState({
      visible: true,
      display_name
    })
  }
}

export default DialogCloneWebshop
