import React, {Component} from 'react'
import {Dialog} from "primereact/dialog";
import {FileUpload} from 'primereact/fileupload';

const BE = process.env.REACT_APP_MTG_BACK

class DialogEditImages extends Component {

  render() {

    return (
      <Dialog
        header={`Upload`}
        visible={this.state.visible}
        // position={'top'}
        style={{width: '65em'}}
        modal={true}
        onHide={() => {
          this.close()
        }}>

        <div className="p-grid">
          <div className="p-col-12" style={{height:'400px'}}>
            <FileUpload name="images[]"
                        accept="image/x-png,image/jpeg,image/jpg"
                        maxFileSize={4000000}
                        multiple={true}
                        onBeforeSend={this.onBeforeSend}
                        auto={false}
                        onUpload={(res) => {
                          console.log('-->',  JSON.parse(res.xhr.response));
                          this.succes()}
                        }
                        url={BE + '/upload-images?product_id=' + this.state.product_id}/>
          </div>
        </div>
      </Dialog>

    )
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  state = {
    visible: false,
    product_id: null
  }

  callback = null

  onBeforeSend(req) {
    let api_token = sessionStorage.getItem('api_token');
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + api_token);
    return req;
  }

  succes(){
    this.callback()
    this.close()
  }

  open(product_id, callback) {

    this.callback = callback
    this.setState({
      visible: true,
      product_id
    })
  }

  close() {
    this.setState({
      visible: false,
      product_id: null
    })
  }
}

export default DialogEditImages
