import React, {Component} from 'react'

import axios from 'axios'
import {InputText} from 'primereact/inputtext'
import money from '../../services/money'
import {lookup as warehouseLookup} from '../../enum/warehouses'
import * as Btw from '../../enum/btw'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'


const inpStyleNumber = {width: '100%', textAlign: 'right', backgroundColor: 'gainsboro'}
const inpStyleText = {width: '100%', textAlign: 'left', backgroundColor: 'gainsboro'}

class ProductProperties extends Component {

  render() {
    if (!this.state.product) return null;
    let {product} = this.state;
    return (
      <div style={{fontSize: '0.9rem',lineHeight:'2rem'}}>
        {/* Below ugly hack to make something work i dont inderstand */}
        <div className="p-grid"><div className="p-col"/></div>
        <div className="p-grid">
          <div className="p-col-4">ID</div>
          <div className="p-col-4">
            <InputText style={inpStyleNumber} readOnly={true} value={this.state.product.id}/>
          </div>
          <div className="p-col-4" style={{textAlign:'right'}}>
            <Button className={this.state.waitingForSync ? 'p-button-secondary' : 'p-button-success'}
                    icon={this.state.waitingForSync ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'}
                    style={{fontSize: '0.75rem'}}
                    onClick={() => this.syncProductWithOdoo()}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Titel</div>
          <div className="p-col-8">
            <InputText style={inpStyleText} readOnly={true} value={this.state.product.display_name}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Titel (engels)</div>
          <div className="p-col-8">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={product.display_name_en_US ? product.display_name_en_US : ''}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">Prijs</div>
          <div className="p-col-2">Standard</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.standard_price, true)}/>
          </div>
          <div className="p-col-2">List</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.list_price, true)}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-2">Internet</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber} readOnly={true}
                       value={money.toEuro(this.state.product.internet_price, true)}/>
          </div>
          <div className="p-col-2">BTW</div>
          <div className="p-col-2">
            <Dropdown style={inpStyleNumber} value={this.state.product.taxes_id} options={Btw.products}
                      disabled={true}/>
          </div>
        </div>
        <hr/>
        {/* Voorraad */}
        <div className="p-grid">
          <div className="p-col-4">Voorraad</div>
          <div className="p-col-2">Fysiek</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_qty_available === null ? '' : this.state.product.mtg_qty_available}/>
          </div>
          <div className="p-col-2">Virtueel</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_virtual_available === null ? '' : this.state.product.mtg_virtual_available}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-2">Min. Hoeveelheid</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.mtg_product_min_qty === null ? '' : this.state.product.mtg_product_min_qty}/>
          </div>
          <div className="p-col-2">
            Show
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.show_on_webshop}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">
            Warehouse
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={warehouseLookup(this.state.product.mtg_leveren_van_magazijn_id)}/>

          </div>

          <div className="p-col-2">Limited Edition</div>
          <div className="p-col-2" style={{textAlign:'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={this.state.product.limited_edition ? 'Yes' : 'No'}/>
          </div>
        </div>

        <hr/>
        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">
            Product Type
          </div>
          <div className="p-col-2">
            <InputText style={inpStyleText}
                       readOnly={true}
                       value={product.type}/>

          </div>

          <div className="p-col-2">MTG Product Type</div>
          <div className="p-col-2" style={{textAlign:'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_product_type ? product.mtg_product_type : ''}/>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-4">
          </div>
          <div className="p-col-2">Leveranciers ID</div>
          <div className="p-col-2">
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_seller_id}/>

          </div>

          <div className="p-col-2">Leveranciers Naam</div>
          <div className="p-col-2" style={{textAlign:'right'}}>
            <InputText style={inpStyleNumber}
                       readOnly={true}
                       value={product.mtg_seller_name}/>
          </div>
        </div>

      </div>
    )
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  state = {
    webshoporder_id: null,
    product: null,
    waitingForSync: false
  }

  constructor(props) {
    super(props);
    this.state.webshoporder_id = props.webshoporder_id;
  }
  componentDidMount() {
    axios.get(process.env.REACT_APP_MTG_BACK + '/get-product-detail',
      {
        params: {product_id: this.props.product_id}
      }).then(response => {
      this.setState({product: response.data})
    });
  }

  syncProductWithOdoo() {
    this.setState({waitingForSync: true});
    axios.get(process.env.REACT_APP_MTG_BACK + '/sync-one-product?id='+this.state.product.id).then((res) => {
      this.props.onsync();
      this.setState({waitingForSync: false});
    })
  }

}

export default ProductProperties
